import { useEffect, useState } from 'react';
import { fabric } from 'fabric';

function ShadowEffect({ canvas }) {
  const [shadowColor, setShadowColor] = useState('#000000');
  const [shadowOffsetX, setShadowOffsetX] = useState(10);
  const [shadowOffsetY, setShadowOffsetY] = useState(10);
  const [shadowBlur, setShadowBlur] = useState(10);
  const [isShadowEnabled, setIsShadowEnabled] = useState(false);

  useEffect(() => {
    if (canvas) {
      canvas.on("selection:created", fetchInitShadow);
      canvas.on("selection:updated", fetchInitShadow);
    }
    return () => {
      if (canvas) {
        canvas.off("selection:created", fetchInitShadow);
        canvas.off("selection:updated", fetchInitShadow);
      }
    };
  }, [canvas ]);

  useEffect(() => {
    if (canvas) {
      const activeObject = canvas.getActiveObject();
      if (activeObject) {
        if (isShadowEnabled) {
          activeObject.set('shadow', new fabric.Shadow({
            color: shadowColor,
            blur: shadowBlur,
            offsetX: shadowOffsetX,
            offsetY: shadowOffsetY,
          }));
        } else {
          activeObject.set('shadow', null);
        }
        canvas.renderAll();
      }
    }
  }, [shadowColor, shadowOffsetX, shadowOffsetY, shadowBlur, isShadowEnabled, canvas]);

const fetchInitShadow = ()=>{
    const activeObject = canvas.getActiveObject();
      if (activeObject && activeObject.shadow) {
        const { color, offsetX, offsetY, blur } = activeObject.shadow;
        console.log("shadow", activeObject.Shadow)
        setShadowColor(color || '#000000');
        setShadowOffsetX(offsetX || 10);
        setShadowOffsetY(offsetY || 10);
        setShadowBlur(blur || 10);
        setIsShadowEnabled(true); // Enable shadow since it's present
      } else {
        console.log("no shadow")
        setShadowColor('#000000');
        setShadowOffsetX(10);
        setShadowOffsetY(10);
        setShadowBlur(10);
        setIsShadowEnabled(false); // Disable shadow if not present
      }
}

  const handleColorChange = (e) => setShadowColor(e.target.value);
  const handleOffsetXChange = (e) => setShadowOffsetX(parseInt(e.target.value, 10));
  const handleOffsetYChange = (e) => setShadowOffsetY(parseInt(e.target.value, 10));
  const handleBlurChange = (e) => setShadowBlur(parseInt(e.target.value, 10));
  const toggleShadow = () => setIsShadowEnabled(!isShadowEnabled);

  return (
    <div className="bg-slate-50 rounded-xl mt-5">
      <div className=" flex text-black ml-2 ">
        <label className="mr-10 text-black">shadow color</label>
        <div>
          <input type="color" 
          value={shadowColor} 
          onChange={handleColorChange} />
        </div>
      </div>

      <div className="text-black ml-2">
        <label>
          Shadow Offset X: {shadowOffsetX}
          <input 
            type="range" 
            min="-150" 
            max="150" 
            className="w-56"
            value={shadowOffsetX} 
            onChange={handleOffsetXChange} 
          />
        </label>
      </div>
      <div className="text-black ml-2">
        <label>
          Shadow Offset Y: {shadowOffsetY}
          <input 
            type="range" 
            min="-150" 
            max="150" 
            className="w-56"
            value={shadowOffsetY} 
            onChange={handleOffsetYChange} 
          />
        </label>
      </div>
      <div className="text-black ml-2">
        <label>
          Shadow Blur: {shadowBlur}
          <input 
            type="range" 
            min="0" 
            max="100" 
            className="w-56"
            value={shadowBlur} 
            onChange={handleBlurChange} 
          />
        </label>
      </div>
      <div className="text-black ml-2">
        <button onClick={toggleShadow} className="mt-2 p-2  bg-gray-300">
          {isShadowEnabled ? 'Disable Shadow' : 'Enable Shadow'}
        </button>
      </div>
    </div>
  );
}

export default ShadowEffect;



