
const FitToCanvasWidth = ({canvas}) => {
 
  // Function to scale objects to fit the canvas width
  const fitObjectsToCanvasWidth = () => {
    const canvasWidth = canvas.getWidth();
    
    const obj = canvas.getActiveObject();
    if(obj){
      const scaleFactor = canvasWidth / obj.width;
      obj.scaleX = scaleFactor;
      obj.scaleY = scaleFactor; 
      obj.left = 0;
      obj.setCoords(); // Update the object's boundaries
    };

    canvas.renderAll();
  };

  return (
    <div>
      <button onClick={fitObjectsToCanvasWidth} className='text-black mr-14 m-2'>Fit to Canvas</button>
    </div>
  );
};

export default FitToCanvasWidth;
