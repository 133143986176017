import React, { useEffect } from 'react';
import { fabric } from 'fabric';

const CustomizeControls = ({ canvas }) => {
  useEffect(() => {
    const duplicateObject = (canvas, target) => {
      target.clone((clonedObj) => {
        canvas.discardActiveObject();
        clonedObj.set({
          left: clonedObj.left + 10,
          top: clonedObj.top + 10,
          evented: true,
        });
        canvas.add(clonedObj);
        canvas.setActiveObject(clonedObj);
        canvas.renderAll();
      });
    };

    const deleteObject = (canvas, target) => {
      canvas.remove(target);
      canvas.renderAll();
    };

    const toggleLockObject = (target) => {
      const isLocked = target.lockMovementX && target.lockMovementY;
      target.set({
        lockMovementX: !isLocked,
        lockMovementY: !isLocked,
        lockScalingX: !isLocked,
        lockScalingY: !isLocked,
        lockRotation: !isLocked,
        hasControls: !isLocked,
        hasBorders: !isLocked,
      });
      canvas.renderAll();
    };

    const addCustomControls = (target) => {
      const deleteIcon = 'data:image/svg+xml;base64,...'; // Base64 encoded delete icon
      const duplicateIcon = 'data:image/svg+xml;base64,...'; // Base64 encoded duplicate icon
      const lockIcon = 'data:image/svg+xml;base64,...'; // Base64 encoded lock icon

      target.controls.deleteControl = new fabric.Control({
        x: 0.5,
        y: -0.5,
        offsetY: -16,
        cursorStyle: 'pointer',
        mouseUpHandler: (eventData, transform) => {
          deleteObject(canvas, transform.target);
          return true;
        },
        render: (ctx, left, top, styleOverride, fabricObject) => {
          const size = 24;
          ctx.save();
          ctx.translate(left, top);
          ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
          ctx.drawImage(deleteIcon, -size / 2, -size / 2, size, size);
          ctx.restore();
        },
      });

      target.controls.duplicateControl = new fabric.Control({
        x: 0.5,
        y: 0.5,
        offsetY: 16,
        cursorStyle: 'pointer',
        mouseUpHandler: (eventData, transform) => {
          duplicateObject(canvas, transform.target);
          return true;
        },
        render: (ctx, left, top, styleOverride, fabricObject) => {
          const size = 24;
          ctx.save();
          ctx.translate(left, top);
          ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
          ctx.drawImage(duplicateIcon, -size / 2, -size / 2, size, size);
          ctx.restore();
        },
      });

      target.controls.lockControl = new fabric.Control({
        x: -0.5,
        y: 0.5,
        offsetX: -16,
        cursorStyle: 'pointer',
        mouseUpHandler: (eventData, transform) => {
          toggleLockObject(transform.target);
          return true;
        },
        render: (ctx, left, top, styleOverride, fabricObject) => {
          const size = 24;
          ctx.save();
          ctx.translate(left, top);
          ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
          ctx.drawImage(lockIcon, -size / 2, -size / 2, size, size);
          ctx.restore();
        },
      });
    };

    const customizeObjectControls = (event) => {
      const activeObject = event.target;
      if (activeObject) {
        addCustomControls(activeObject);
        canvas.renderAll();
      }
    };

    if (canvas) {
      canvas.on('object:selected', customizeObjectControls);
    }

    return () => {
      if (canvas) {
        canvas.off('object:selected', customizeObjectControls);
      }
    };
  }, [canvas]);

  return null;
};

export default CustomizeControls;


