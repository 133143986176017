

import React from "react";
import { Link } from "react-router-dom";

const PostCard = ({ post , onClick }) => {
  return (
    
    <div className="m-4 p-4 bg-white shadow-md rounded" >
      {post.media.length > 0 && post.media[0].type === "image" && (
        <img src={post.media[0].url} alt="Post media" className="h-64 w-60" />
      )}
      <p className="mt-2 w-60 truncate">{post.content}</p>
      <div>
        <Link to={`/view/${post.postId}`} className="text-blue-500">
          Edit
        </Link>
        <p>Status : {post.status}</p>
      </div>
    </div>
  );
};

export default PostCard;
