import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate  } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { postData, groups, contacts } from "./DummyData";
import SwitchToggle from "./toggleSwitch"
import QuillEditor from "./QuillEditor";

const EmailView = () => {

  const { Id } = useParams();
  const navigate = useNavigate();
  const [content, setContent] = useState({});
  const [quillContent, setQuillContent] = useState('');
  const [savedHtml, setSavedHtml] = useState('')
  const [isDesigner, setIsDesigner] = useState(false)
  const [textAreaValue, setTextAreaValue] = useState("");
  const [subject, setSubject] = useState("");
  const [scheduleDate, setScheduleDate] = useState(null);
  const [isScheduled , setIsScheduled] = useState(false);
  const [status, setStatus] = useState("");
  const [contactList, setContactList] = useState([]);
  const [allContactsAndGroups, setAllContactsAndGroups] = useState([]);

  useEffect(() => {
    // Combine all contact emails and group names into one list
    const contactsEmails = contacts.map(contact => ({ type: "contact", value: contact.email }));
    const groupsNames = groups.map(group => ({ type: "group", value: group.group_name }));
    const combinedList = [...contactsEmails, ...groupsNames];

    setAllContactsAndGroups(combinedList);
  }, []);

  useEffect(() => {
    const data = postData.find(data => data.postId === Id);
    if (data) {
      setContent(data);
      setTextAreaValue(data.content);
      setSubject(data.subject);
      setStatus(data.status);
    }
  }, [Id]);

  const handleEditorChange = (newContent) => {
    setQuillContent(newContent);
  };
  const handleContactList = (e) => {
    if (e.target.value && !contactList.includes(e.target.value)) {
      setContactList([...contactList, e.target.value]);
    }
  };

  const handleRemoveContact = (contactToRemove) => {
    setContactList(contactList.filter(contact => contact !== contactToRemove));
  };

  const handleTextareaChange = (e) => {
    setTextAreaValue(e.target.value.slice(0, 280));
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value.slice(0, 60));
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };
  const toggleSchedule = () => {
    setIsScheduled(!isScheduled);
  };

  const handleSave = () => {
    // The quillContent is already in HTML format, so we can save it directly
    setSavedHtml(quillContent)
    console.log('Saved HTML:', quillContent)
    
    // Here you would typically send this to your backend or storage
    // For example:
    // saveToBackend(quillContent)
  }

  const handleSchedule = () => {
    // Logic to schedule the post
    console.log("Scheduled");
  };

  const handlePublish = () => {
    // Logic to publish the post
    console.log("Published");
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="w-[100%] bg-slate-100 h-screen overflow-y-auto">
      <div className=" h-16"></div>
      <div className="flex">
        <div className="flex flex-col items-center bg-white w-[74%] ml-[2%] rounded-xl">

            <section className="flex  items-center w-[96%] mb-4 bg-white " name="contact list">

              <div className="flex items-center mt-2 mb-5  w-[66%]">
              <p className=" mb-4 ml-5 w-[5%]">To:</p>
                <ul className="flex flex-wrap w-[95%] list-disc list-inside">
                    {contactList.map((contact, index) => (
                        <li className="flex items-center mb-2 mr-2" key={index}>
                        {contact}
                        <button
                            className="ml-2 text-red-500 hover:text-red-700"
                            onClick={() => handleRemoveContact(contact)}
                        >
                            &times;
                        </button>
                        </li>
                    ))}
                </ul>
              </div> 

              <div className="flex items-center mt-2 mb-5 w-[20%]">
                  <select
                  value=""
                  onChange={handleContactList}
                  className="border rounded px-2 py-1 mr-2"
                  >
                  <option value="">Add contacts / groups</option>
                  {allContactsAndGroups.map((item, index) => (
                      <option key={index} value={item.value}>
                      {item.value}
                      </option>
                  ))}
                  </select>
              </div>   
            </section>
            <hr className="w-[94%] ml-[1%] mb-4 bg-gray" />

            <section className="w-[96%] bg-white " name="subject" >
                <div className="mb-1 mt-4 ml-5">
                    <textarea
                    className="w-full h-10 overflow-y-auto border-none"
                    placeholder="Subject"
                    value={subject}
                    onChange={handleSubjectChange}
                    maxLength={60}
                    required
                    ></textarea>
                </div>
            </section>

            <hr className="w-[94%] ml-[1%] mb-4 bg-gray" />

            <section className="w-[96%] mt-5 mb-5 bg-white" name="content">
              {isDesigner ? (
                <div>
                  <Link to={`/editor/${content.postId}`} className="">
                    {content.media && content.media.length > 0 && content.media.map((media, index) => (
                      <div key={index} className="mb-4">
                        {media.type === "image" && (
                          <img src={media.url} alt={`Media ${index + 1}`} className="w-full rounded mb-4" />
                        )}
                      </div>
                    ))}
                  </Link>
                </div>
                ) : (
                <div>
                  <QuillEditor value={quillContent} onChange={handleEditorChange} />
                </div>
              )}
            </section>
            /// section for email signature
            <hr className="w-[96%] mb-4" />   
        </div>
        
        <div className="flex flex-col items-center ml-[2%] w-[20%] bg-white rounded-xl">
            <div className="flex flex-col items-center w-[100%] mt-[5%] " >
                <button
                    onClick={handleSave} 
                    className="w-[80%] bg-green-500 hover:bg-green-600 text-white font-bold rounded-lg h-8  "
                > 
                  Save
                </button>
                <button
                    className="w-[80%] bg-blue-500 hover:bg-blue-600 text-white font-bold rounded-lg h-8 mt-[4%] "
                >
                  Publish
                </button>
                <button
                  className="w-[80%] bg-orange-500 hover:bg-orange-600 text-white font-bold rounded-lg h-8 mt-[4%] "
                >
                  Preview
                </button>
                <button
                  onClick={toggleSchedule}
                  className="w-[80%] bg-purple-500 hover:bg-purple-600 text-white font-bold rounded-lg h-8 mt-[4%] "
                >
                  Schedule
                </button>

                { isScheduled && (
                  <div className="mb-4">
                    <label htmlFor="scheduleDate" className="block mb-2">Schedule Date:</label>
                    <DatePicker
                      id="scheduleDate"
                      selected={scheduleDate}
                      onChange={(date) => setScheduleDate(date)}
                      showTimeSelect
                      dateFormat="Pp"
                      className="border rounded px-2 py-1 w-full"
                    />
                  </div>
                )}

                <div className="mt-[5%]  border-[1px] rounded-lg">
                  <SwitchToggle isDesigner={isDesigner} setIsDesigner={setIsDesigner} />
                </div>
            </div>
        </div>

        </div>
        <div className=" h-16"></div>
    </div>
  );
};

export default EmailView;