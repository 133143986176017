import { useState } from 'react';
import { IoIosArrowDown } from "react-icons/io";
import { CgDropOpacity } from "react-icons/cg";
import FontFamilyUtil from "./utils/FontFamilyUtils"
import FontSize from "./utils/FontSizeUtils"
import FontAlign from "./utils/FontAlignUtils"
import TextStyles from "./utils/TextStylesUtils"
import ColorPicker from "./utils/ColorUtil"
import UNDO_REDO from './utils/UNDO_REDO';
import Opacity from './utils/Opacity';
import DeleteElement from './utils/DeleteElementUtils';
import DuplicateElement from './utils/DuplicateUtils';
import LockElement from './utils/LockElements';
//import GroupElements from './utils/CustomizeControls';
import ShadowEffect from './utils/effectsUtils';
import GradientEffect from './utils/GradientEffects';
import BorderColor from './utils/BorderColorUtil';
import FitToCanvasWidth from './utils/FitToCanvasWidth';
//import CanvasColor from './utils/CanvasBackgroundColor';



const UtilBar = ({canvas })=>{
    const[effectState, setEffectState] = useState(false)
    const [showSlider, setShowSlider] = useState(false);

    const handleEffectState = ()=>{
        setEffectState(!effectState)
      }
      const toggleSlider = () => {
        setShowSlider(!showSlider);
      };
    
    return(
        <div className="bg-white w-72  text-white h-[91vh] shadow-md">
            <div className="flex">
                <br/>
                <UNDO_REDO canvas={canvas}/>
                <CgDropOpacity onClick={toggleSlider} className="text-black shadow-sm mt-2 mr-4 w-10 h-6" />
                <LockElement canvas={canvas}/>
                <DuplicateElement canvas={canvas}/>
                <DeleteElement canvas={canvas}/>
                <br/>
            </div>
            {showSlider && (
                <Opacity canvas={canvas}/>
            )}
            <FontFamilyUtil canvas={canvas}/>
            <div className="flex ml-2">
                <FontSize canvas={canvas}/>
                <FontAlign canvas={canvas} />
            </div>
            <br/>
            <TextStyles canvas={canvas}/>
            <br/>
            <div>
                <ColorPicker canvas={canvas} />
                <BorderColor canvas={canvas} />
                
            </div>
            <GradientEffect canvas={canvas}/>
            <FitToCanvasWidth canvas={canvas}/>
            <div className="flex mt-5">
                <label className="text-black mr-14 ml-2 cursor-pointer ">effects</label>
                <div onClick={handleEffectState} className="text-black bg-slate-50 rounded-xl w-40 h-7 cursor-pointer "><IoIosArrowDown className=" ml-20 mt-2"  /></div>
            </div>
            {effectState && (
            <div>
                <ShadowEffect canvas={canvas}/>
            </div> )}
        </div>
    );
};

export default UtilBar;