//import { Bars3BottomLeftIcon, Bars3BottomRightIcon, Bars3CenterLeftIcon } from "@heroicons/react/24/solid";
import { CiAlignLeft } from "react-icons/ci";
import { CiAlignCenterH } from "react-icons/ci";
import { CiAlignRight } from "react-icons/ci";
import { useState, useEffect } from "react";
import { setTextAlign } from "./TextUtils";

const FontAlign = ({ canvas }) => {
  const [textAlign, setTextAlignState] = useState("left");

  useEffect(() => {
    
    const updateTextProperties = () => {
      try{
        const activeObject = canvas?.getActiveObject();
        if (activeObject && activeObject.type === 'textbox') {
          setTextAlignState(activeObject.textAlign || "left");
        }
      }catch(e){}
    };

    if (canvas) {
      canvas.on("selection:created", updateTextProperties);
      canvas.on("selection:updated", updateTextProperties);
    }

    return () => {
      if (canvas) {
        canvas.off("selection:created", updateTextProperties);
        canvas.off("selection:updated", updateTextProperties);
      }
    };
  }, [canvas]);

  const handleTextAlignChange = (value) => () => {
    try{
      
    setTextAlignState(value);
    setTextAlign(canvas, value);

    }catch(e){}
  };

  return (
    <div className="flex">
      <div className="mr-2 ml-3">
        <CiAlignLeft size={24}
          className={`h-7 cursor-pointer ${textAlign === "left" ? "text-blue-500" : "text-slate-500"}`}
          onClick={handleTextAlignChange("left")}
        />
      </div>
      <div className="mr-2 ml-2">
        <CiAlignCenterH size={24}
          className={`h-7 cursor-pointer ${textAlign === "center" ? "text-blue-500" : "text-slate-500"}`}
          onClick={handleTextAlignChange("center")}
        />
      </div>
      <div className="ml-2">
        <CiAlignRight size={24}
          className={`h-7 cursor-pointer ${textAlign === "right" ? "text-blue-500" : "text-slate-500"}`}
          onClick={handleTextAlignChange("right")}
        />
      </div>
    </div>
  );
};

export default FontAlign;
