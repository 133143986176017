import React, { useState ,useEffect} from 'react';
import { fabric } from 'fabric';

function GradientEffect({ canvas }) {
  const [gradientStartColor, setGradientStartColor] = useState('#aeaeb9');
  const [gradientEndColor, setGradientEndColor] = useState('#aeaeb9');

  useEffect(() => {
    if (canvas) {
      canvas.on("selection:created", fetchInitGradient);
      canvas.on("selection:updated", fetchInitGradient);
    }
    return () => {
      if (canvas) {
        canvas.off("selection:created", fetchInitGradient);
        canvas.off("selection:updated", fetchInitGradient);
      }
    };
  }, [canvas]);

  // useEffect(()=>{

  //   applyGradient()

  // }, [gradientEndColor,gradientStartColor])

  const applyGradient = () => {
    if (canvas) {
      const activeObject = canvas.getActiveObject();
      if (activeObject) {
        const gradient = new fabric.Gradient({
            type: 'linear',
            coords: { x1: 0, y1: 0, x2: activeObject.width, y2: 0 },
            colorStops: [
              { offset: 0, color: gradientStartColor },
              { offset: 1, color: gradientEndColor },
            ]
          });
          activeObject.set('fill', gradient);
          canvas.renderAll();
      }
    }
  };

  const fetchInitGradient = () => {
    // if (canvas) {
    //   const activeObject = canvas.getActiveObject();
    //   if (activeObject && activeObject.fill.type === 'linear') {
    //     const { colorStops } = activeObject.fill;
    //     if (colorStops.length === 2) {
    //       setGradientStartColor(colorStops[0].color);
    //       setGradientEndColor(colorStops[1].color);
    //     }
    //   } else {
    //     // Reset to defaults if no gradient or unsupported object type
    //     setGradientStartColor(activeObject.fill);
    //     setGradientEndColor(activeObject.fill);
    //   }
    // }
  };
  return (
    <div>
      <div className=" flex text-black ml-2 mt-5">
        <label className="mr-10">Gradient </label>

        <div className="flex bg-slate-50 rounded-xl w-40 " >
          <input type="color" 
          value={gradientStartColor}
           onChange={(e) => setGradientStartColor(e.target.value)} 
           className="h-7 w-16 rounded-full mr-12 ml-1" />

          <input type="color"
           value={gradientEndColor} 
           onChange={(e) => setGradientEndColor(e.target.value)} 
           className="h-7 w-16 rounded-full mr-12 ml-1"/>
        </div>
      </div>
    </div>
  );
}

export default GradientEffect;
