import { useState, useEffect } from "react";
import {setFontSize} from "./TextUtils"

const FontSize = ({canvas})=>{

    //const[fontSize,setFontSize] = useState(20)
    const[fontSize , setFontSizeState] = useState(16)

    useEffect(() => {
        if (canvas) {
          canvas.on("selection:created", updateTextProperties);
          canvas.on("selection:updated", updateTextProperties);
        }
        return () => {
          if (canvas) {
            canvas.off("selection:created", updateTextProperties);
            canvas.off("selection:updated", updateTextProperties);
          }
        };
      }, [canvas]);

      const updateTextProperties = () => {
        try{
            const activeObject = canvas.getActiveObject();
            if (activeObject && activeObject.type === 'textbox') {
              //setFontFamilyState(activeObject.fontFamily || "Arial");
              setFontSizeState(activeObject.fontSize || 16);
            }
          }catch(e){}
      };

    const handleFontSizeChange = (event) => {
      try{
        const selectedFontSize = event.target.value;
        setFontSizeState(selectedFontSize);
        setFontSize(canvas, selectedFontSize);
      }catch(e){}
      };


    return(
        <div className="w-20 text-black mr-16">
            <div className="flex items-center space-x-2">
                <input
                type="number"
                value={fontSize}
                onChange={handleFontSizeChange}
                className="w-20 h-7 text-center border-2 border-slate-300 rounded-xl "
                />
                
            </div>
        </div>
    );
};

export default FontSize;