import  { useState } from "react";
import Campaigns from "./campaigns"
import SideBar from "./SideBar";
import { IoAdd } from "react-icons/io5";
import AddCampaignForm from "./addCampaignForm";


const Library = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleFormSubmit = (campaignName) => {
    // Add logic to handle form submission, like updating the campaigns array
    console.log("Campaign Name:", campaignName);
  };

  return (
    <div className="flex">
      <SideBar />
      <Campaigns />
      <button
        className="fixed bottom-10 right-10 flex items-center justify-center bg-blue-500 w-20 h-20 rounded-full text-white cursor-pointer"
        onClick={() => setIsPopupOpen(true)}
      >
        <IoAdd size={28} />
      </button>

      {isPopupOpen && (
        <AddCampaignForm
          onClose={() => setIsPopupOpen(false)}
          onSubmit={handleFormSubmit}
        />
      )}
    </div>
  );
};

export default Library;

