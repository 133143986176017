import React from 'react'

const Switcher11 = ({ isDesigner, setIsDesigner }) => {
  const handleCheckboxChange = () => {
    setIsDesigner(!isDesigner)
  }

  return (
    <div>
      <label className='themeSwitcherTwo shadow-card relative inline-flex cursor-pointer select-none items-center justify-center rounded-md bg-white p-1 shadow-md mt-[5px] '>
        <input
          type='checkbox'
          className='sr-only'
          checked={isDesigner}
          onChange={handleCheckboxChange}
        />
        <span
          className={`flex items-center space-x-[6px] rounded py-2 px-[18px] text-sm font-medium ${
            !isDesigner ? 'text-primary bg-[#f4f7ff]' : 'text-body-color'
          }`}
        >
          Regular
        </span>
        <span
          className={`flex items-center space-x-[6px] rounded py-2 px-[18px] text-sm font-medium ${
            isDesigner ? 'text-primary bg-[#f4f7ff]' : 'text-body-color'
          }`}
        >
          Designer
        </span>
      </label>
    </div>
  )
}

export default Switcher11


