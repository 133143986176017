export const convertCanvasToHTML = (canvas) => {
  const width = 900;
  const height = 450;
  const scaleWidth = window.innerWidth / width;
  const scaleHeight = window.innerHeight / height;

  let htmlContent = `
    <div style="
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      background-color: ${canvas.backgroundColor};
      align-items: center;
      overflow: auto;
    ">
  `;

  const createGradientDef = (fill, svgId) => {

    if (fill.type === 'linear') {
      const gradientColors = fill.colorStops.map(stop => 
        `<stop offset="${stop.offset}" stop-color="${stop.color}"/>`
      ).join('');

      return `
        <defs>
          <linearGradient id="${svgId}" x1="${fill.coords.x1}" y1="${fill.coords.y1}" x2="${fill.coords.x2}" y2="${fill.coords.y2}">
            ${gradientColors}
          </linearGradient>
        </defs>
      `;
    } else if (fill.type === 'radial') {
      const gradientColors = fill.colorStops.map(stop => 
        `<stop offset="${stop.offset}" stop-color="${stop.color}"/>`
      ).join('');

      return `
        <defs>
          <radialGradient id="${svgId}" cx="${fill.coords.x1}" cy="${fill.coords.y1}" r="${fill.coords.r1}">
            ${gradientColors}
          </radialGradient>
        </defs>
      `;
    }
    return '';
  };

  canvas.getObjects().forEach((obj, index) => {
    if (obj.excludeFromHTML) {
      return; 
    }
    const commonStyles = `
      position: absolute;
      left: ${obj.left * scaleWidth}px;
      top: ${obj.top * scaleHeight}px;
      width: ${obj.width * obj.scaleX * scaleWidth}px;
      height: ${obj.height * obj.scaleY * scaleHeight}px;
      transform: rotate(${obj.angle}deg);
      opacity: ${obj.opacity};
      ${obj.shadow ? `box-shadow: ${obj.shadow.offsetX * scaleWidth}px ${obj.shadow.offsetY * scaleHeight}px ${obj.shadow.blur}px ${obj.shadow.color};` : ''}
    `;

    const svgId = `gradient-${index}`;
    const gradientDef = createGradientDef(obj.fill, svgId);
    const fillStyle = obj.fill.type === 'linear' || obj.fill.type === 'radial' ? `url(#${svgId})` : obj.fill;

    // if (obj.type === 'path') {
    //   const pathData = obj.path.map(cmd => cmd.join(' ')).join(' ');
    
    //   // Get the bounding box of the path itself, not just the object
    //   const pathBoundingBox = obj.getBoundingRect(true);
    
    //   console.log(obj.toSVG());
    //   // Adjust styles for the container div and set correct dimensions and positioning
    //   htmlContent += `
    //     <div style="position: absolute; left: ${pathBoundingBox.left}px; top: ${pathBoundingBox.top}px; 
    //       width: ${pathBoundingBox.width}px; height: ${pathBoundingBox.height}px;">
    //       <svg width="100%" height="100%" viewBox="0 0 ${pathBoundingBox.width} ${pathBoundingBox.height}">
    //         ${gradientDef || ''} <!-- Only include if a gradient is needed -->
    //         <path d="${pathData}" fill="${obj.fill || 'none'}" 
    //           ${obj.stroke ? `stroke="${obj.stroke}" stroke-width="${obj.strokeWidth}"` : ''}
    //           vector-effect="non-scaling-stroke" <!-- This keeps stroke width proportional -->
    //         />
    //       </svg>
    //     </div>
    //   `;
    // }
    
    if (obj.type === 'path') {
      // Use Fabric.js's built-in `toSVG()` method to get the inner SVG content
      const innerSVG = obj.toSVG();
    
      // Get the bounding box of the object for proper positioning
      const pathBoundingBox = obj.getBoundingRect(true);
    
      // Extract original transform values from `innerSVG`
      const transformMatch = innerSVG.match(/transform="matrix\(([^)]+)\)"/);
      const originalTransform = transformMatch ? transformMatch[1].split(' ').map(Number) : [1, 0, 0, 1, 0, 0];
    
      // Calculate translation offsets
      const translationX = -pathBoundingBox.left + originalTransform[4];
      const translationY = -pathBoundingBox.top + originalTransform[5];
    
      // Adjust the transform matrix values
      const adjustedTransform = `matrix(${originalTransform[0]* 1.055} 0 0 ${originalTransform[3]} ${translationX} ${translationY})`;
    
      // Replace the transform attribute in the inner SVG
      const adjustedInnerSVG = innerSVG.replace(
        /transform="matrix\([^)]+\)"/,
        `transform="${adjustedTransform}"`
      );
    
      // Create a proper SVG wrapper and insert the adjusted `toSVG` output inside it
      htmlContent += `
        <svg style="position: absolute; left: ${pathBoundingBox.left * scaleWidth}px; top: ${pathBoundingBox.top * scaleHeight}px; 
          width: ${pathBoundingBox.width * scaleWidth}px; height: ${pathBoundingBox.height * scaleHeight}px;" 
          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${pathBoundingBox.width} ${pathBoundingBox.height}">
          ${adjustedInnerSVG} <!-- Insert the adjusted inner SVG -->
        </svg>
      `;
    } else if (obj.type === 'rect') {
      if (obj.fill.type === 'linear' || obj.fill.type === 'radial') {
        htmlContent += `
          <div style="${commonStyles}">
            <svg width="100%" height="100%" viewBox="0 0 ${obj.width} ${obj.height}">
              ${gradientDef}
              <rect width="100%" height="100%" fill="${fillStyle}"
                ${obj.stroke ? `stroke="${obj.stroke}" stroke-width="${obj.strokeWidth}"` : ''}
                ${obj.rx ? `rx="${obj.rx}"` : ''}
                ${obj.ry ? `ry="${obj.ry}"` : ''}
              />
            </svg>
          </div>
        `;
      } else {
        htmlContent += `
          <div style="${commonStyles}
            background-color: ${fillStyle};
            ${obj.stroke ? `border: ${obj.strokeWidth}px solid ${obj.stroke};` : ''}
            ${obj.rx || obj.ry ? `border-radius: ${obj.rx * scaleWidth || 0}px / ${obj.ry * scaleHeight|| 0}px;` : ''}
          "></div>
        `;
      }
      
    } else if (obj.type === 'circle') {
      htmlContent += `
        <div style="${commonStyles}">
          <svg width="100%" height="100%" viewBox="0 0 ${obj.width} ${obj.height}">
            ${gradientDef}
            <circle cx="${obj.width/2}" cy="${obj.height/2}" r="${Math.min(obj.width, obj.height)/2}" fill="${fillStyle}" 
              ${obj.stroke ? `stroke="${obj.stroke}" stroke-width="${obj.strokeWidth}"` : ''}
            />
          </svg>
        </div>
      `;
    } else if (obj.type === 'triangle') {
      htmlContent += `
        <div style="${commonStyles}">
          <svg width="100%" height="100%" viewBox="0 0 ${obj.width} ${obj.height}">
            ${gradientDef}
            <polygon points="0,${obj.height} ${obj.width/2},0 ${obj.width},${obj.height}" fill="${fillStyle}" 
              ${obj.stroke ? `stroke="${obj.stroke}" stroke-width="${obj.strokeWidth}"` : ''}
            />
          </svg>
        </div>
      `;
    } else if (obj.type === 'polygon') {
      const points = obj.points.map(point => `${point.x},${point.y}`).join(' ');
      htmlContent += `
        <div style="${commonStyles}">
          <svg width="100%" height="100%" viewBox="0 0 ${obj.width} ${obj.height}">
            ${gradientDef}
            <polygon points="${points}" fill="${fillStyle}" 
              ${obj.stroke ? `stroke="${obj.stroke}" stroke-width="${obj.strokeWidth}"` : ''}
            />
          </svg>
        </div>
      `;
    } else if (obj.type === 'textbox' || obj.type === 'i-text') {
      htmlContent += `
        <div style="
          ${commonStyles}
          font-size: ${obj.fontSize * scaleHeight}px;
          font-family: ${obj.fontFamily || 'sans-serif'};
          color: ${fillStyle};
          text-align: ${obj.textAlign || 'left'};
          line-height: ${obj.lineHeight || 'normal'};
          font-weight: ${obj.fontWeight || 'normal'};
          font-style: ${obj.fontStyle || 'normal'};
          text-decoration: ${obj.underline ? 'underline' : 'none'} ${obj.linethrough ? 'line-through' : ''};
          letter-spacing: ${obj.charSpacing ? obj.charSpacing / 1000 : 0}em;
          opacity: ${obj.opacity || 1};
          
        ">
          ${obj.text.replace(/\n/g, '<br/>')}
        </div>
      `;

    } else if (obj.type === 'image') {
      const imgSrc = obj.toDataURL();
      htmlContent += `<img src="${imgSrc}" style="${commonStyles}"/>`;
    } else if (obj.type === 'line' || obj.type === 'polyline') {
      const svgContent = obj.toSVG();
      htmlContent += `
        <div style="${commonStyles}">
          <svg width="100%" height="100%" viewBox="0 0 ${obj.width} ${obj.height}">
            ${gradientDef}
            ${svgContent.replace(/fill="[^"]*"/, `fill="${fillStyle}"`)}
          </svg>
        </div>
      `;
    } else {
      // Use Fabric.js's built-in `toSVG()` method to get the inner SVG content
      const innerSVG = obj.toSVG();
    
      // Get the bounding box of the object for proper positioning
      const pathBoundingBox = obj.getBoundingRect(true);
    
      // Extract original transform values from `innerSVG`
      const transformMatch = innerSVG.match(/transform="matrix\(([^)]+)\)"/);
      const originalTransform = transformMatch ? transformMatch[1].split(' ').map(Number) : [1, 0, 0, 1, 0, 0];
    
      // Calculate translation offsets
      const translationX = -pathBoundingBox.left + originalTransform[4];
      const translationY = -pathBoundingBox.top + originalTransform[5];
    
      // Adjust the transform matrix values
      const adjustedTransform = `matrix(${originalTransform[0]* 1.055} 0 0 ${originalTransform[3]} ${translationX} ${translationY})`;
    
      // Replace the transform attribute in the inner SVG
      const adjustedInnerSVG = innerSVG.replace(
        /transform="matrix\([^)]+\)"/,
        `transform="${adjustedTransform}"`
      );
    
      // Create a proper SVG wrapper and insert the adjusted `toSVG` output inside it
      htmlContent += `
        <svg style="position: absolute; left: ${pathBoundingBox.left * scaleWidth}px; top: ${pathBoundingBox.top * scaleHeight}px; 
          width: ${pathBoundingBox.width * scaleWidth}px; height: ${pathBoundingBox.height * scaleHeight}px;" 
          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${pathBoundingBox.width} ${pathBoundingBox.height}">
          ${adjustedInnerSVG} <!-- Insert the adjusted inner SVG -->
        </svg>
      `;
    }
  });

  htmlContent += ` </br></div>`;
  return htmlContent;
};

  
 export const downloadHTML = (htmlContent, filename = 'canvas.html') => {
    const blob = new Blob([htmlContent], { type: 'text/html' });
    const link = document.createElement('a');
  
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  