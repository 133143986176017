import React, { useState } from "react";
import { IoAdd } from "react-icons/io5";
import SideBar from "./SideBar";
import PostCard from "./PostCard";
import EmailCard from "./EmailCard";
import ContentForm from "./ContentForm";
import ViewContent from "./ViewContent";
import { useParams } from 'react-router-dom';
import { data , campaigns} from "./DummyData";

const ManualCampaignContent = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isViewPopupOpen, setIsViewPopupOpen] = useState(false);
  const [view, setView] = useState("posts");
  const { campaignId, campaignType } = useParams();
  const [selectedContent, setSelectedContent] = useState(null);


  const handleViewContent = (content) => {
    setSelectedContent(content);
    setIsViewPopupOpen(true);
    console.log(campaignType);
  };

  return (
    <div className="flex w-full">
      <SideBar />
      { campaignType == "manual" && (
        <div className="flex flex-col w-full items-center bg-slate-100">
            <div className="flex flex-col w-full items-center">
                <div className="h-20"></div>
                <div className="flex flex-wrap">
                {view === "posts" &&
                    data.filter((item) => !item.subject).map((post) => (
                    <PostCard key={post.postId} post={post} onClick={() => handleViewContent(post)} />
                    ))}
                {view === "emails" &&
                    data.filter((item) => item.subject).map((email) => (
                    <EmailCard key={email.postId} email={email} onClick={() => handleViewContent(email)} />
                    ))}
                </div>
                <div>
                <button
                    className="fixed bottom-10 right-10 flex items-center justify-center bg-blue-500 w-20 h-20 rounded-full text-white cursor-pointer"
                    onClick={() => setIsPopupOpen(true)}
                >
                    <IoAdd size={28} />
                </button>
                </div>
            </div> 

            {isPopupOpen && (
                <ContentForm onClose={() => setIsPopupOpen(false)} />
            )}
            {isViewPopupOpen && selectedContent && (
                <ViewContent content={selectedContent} onClose={() => setIsViewPopupOpen(false)} />
            )}

        </div> 
      )}
    </div>
  );
};

export default ManualCampaignContent;