import { useState, useEffect } from "react";
import {setFontFamily} from "./TextUtils"

const FontFamilyUtil = ({canvas})=>{
    const [fontFamily, setFontFamilyState] = useState("Arial"); 

     // Update font family when selection changes
  useEffect(() => {
    if (canvas) {
      canvas.on("selection:created", updateFontFamily);
      canvas.on("selection:updated", updateFontFamily);
    }
    return () => {
      if (canvas) {
        canvas.off("selection:created", updateFontFamily);
        canvas.off("selection:updated", updateFontFamily);
      }
    };
  }, [canvas]);

    const updateFontFamily = () => {
      try{
        const activeObject = canvas.getActiveObject();
        if (activeObject && activeObject.type === 'textbox') {
          setFontFamilyState(activeObject.fontFamily || "Arial");
        }
      }catch(e){}
      };

    const handleFontFamilyChange = (event) => {
      try{
        console.log(event.target.value)
        setFontFamily(canvas, event.target.value);
        setFontFamilyState(event.target.value)
      }catch(e){}
      };

    return(
        <div className=" bg-white w-72 text-white h-16 mt-5 ">
            <div>
                <select 
                value={fontFamily}
                className="bg-white border-2 border-slate-300 text-black w-[260px] h-10 rounded-xl ml-2" 
                onChange={handleFontFamilyChange}>
                    <option className="font-arial" value="Arial">Arial</option>
                    <option className="font-courier-new" value="Courier New">Courier New</option>
                    <option className="font-georgia" value="Georgia">Georgia</option>
                    <option className="font-times-new-roman" value="Times New Roman">Times New Roman</option>
                    <option className="font-verdana" value="Verdana">Verdana</option>
                    <option className="font-tahoma" value="Tahoma">Tahoma</option>
                    <option className="font-trebuchet-ms" value="Trebuchet MS">Trebuchet MS</option>
                    <option className="font-comic-sans-ms" value="Comic Sans MS">Comic Sans MS</option>
                    <option className="font-impact" value="Impact">Impact</option>
                    <option className="font-lucida-sans" value="Lucida Sans">Lucida Sans</option>
                    <option className="font-palatino-linotype" value="Palatino Linotype">Palatino Linotype</option>
                    <option className="font-book-antiqua" value="Book Antiqua">Book Antiqua</option>
                    <option className="font-arial-black" value="Arial Black">Arial Black</option>
                    <option className="font-gadget" value="Gadget">Gadget</option>
                    <option className="font-lucida-console" value="Lucida Console">Lucida Console</option>
                    <option className="font-monaco" value="Monaco">Monaco</option>
                    <option className="font-copperplate" value="Copperplate">Copperplate</option>
                    <option className="font-papyrus" value="Papyrus">Papyrus</option>
                    <option className="font-brush-script-mt" value="Brush Script MT">Brush Script MT</option>
                    <option className="font-garamond" value="Garamond">Garamond</option>
                    <option className="font-rockwell" value="Rockwell">Rockwell</option>
                    <option className="font-calibri" value="Calibri">Calibri</option>
                    <option className="font-candara" value="Candara">Candara</option>
                    <option className="font-futura" value="Futura">Futura</option>
                    <option className="font-gill-sans" value="Gill Sans">Gill Sans</option>
                </select>
            </div>
        </div>
    );
};

export default FontFamilyUtil;