import React, { useState } from "react";
import { IoAdd } from "react-icons/io5";
import SideBar from "./SideBar";
import { useParams } from 'react-router-dom';
import ManualCampaignContent from "./manualCampaignContent";
import AutomaticCampaignContent from "./automaticCampaignContent";

const CampaignContent = () => {
 
  const [view, setView] = useState("posts");
  const { campaignId, campaignType } = useParams();
 

  return (
    <div className="flex">
      { campaignType == "manual" && (
       <ManualCampaignContent campaignId={campaignId}/>
      )}

      { campaignType == "automatic" && (
        <AutomaticCampaignContent campaignId={campaignId}/>
       )}
    </div>
  );
};

export default CampaignContent;