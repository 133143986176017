import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import ContentForm from './ContentForm'; 
import ViewContent from './ViewContent';
import Popup from './SchedulerPopUp'
import { data } from './DummyData'; 

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const formatDataToEvents = (data) => {
  return data.map(post => ({
    id: post.postId,
    title: post.content,
    start: new Date(post.createdAt),
    end: new Date(post.updatedAt), 
    allDay: false, 
    status: post.status,
    platforms: post.platforms,
    tags: post.tags,
  }));
};

const Scheduler = () => {
  const [events, setEvents] = useState(formatDataToEvents(data));
  const [isViewPopupOpen, setIsViewPopupOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedContent, setSelectedContent] = useState(null);

  const handleSelectSlot = (slotInfo) => {
    setSelectedSlot(slotInfo);
    setSelectedEvent(null);
    setShowForm(true);
  };

  const handleSelectEvent = (event) => {
    const post = data.find(post => post.postId === event.id);
    setSelectedEvent(event);
    setSelectedSlot(null);
    setSelectedContent(post);
    setIsViewPopupOpen(true);
  };

  const handleFormClose = () => {
    setShowForm(false);
    setSelectedEvent(null);
    setSelectedSlot(null);
  };

  const handleFormSubmit = (formData) => {
    if (selectedEvent) {
      // Edit existing event
      const updatedEvents = events.map(event =>
        event.id === selectedEvent.id ? { ...event, ...formData } : event
      );
      setEvents(updatedEvents);
    } else {
      // Add new event
      const newEvent = {
        id: `post${events.length + 1}`,
        title: formData.content,
        start: new Date(selectedSlot.start),
        end: new Date(selectedSlot.end),
        allDay: false,
        ...formData,
      };
      setEvents([...events, newEvent]);
    }
    handleFormClose();
  };

  const moveEvent = ({ event, start, end }) => {
    const updatedEvent = { ...event, start, end };
    const nextEvents = events.map(evt => (evt.id === event.id ? updatedEvent : evt));
    setEvents(nextEvents);
  };

  const resizeEvent = ({ event, start, end }) => {
    const nextEvents = events.map(evt =>
      evt.id === event.id ? { ...evt, start, end } : evt
    );
    setEvents(nextEvents);
  };

  return (
    <div style={{ height: '90vh' }} className="w-[100%]">
      <DnDCalendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        selectable
        onSelectSlot={handleSelectSlot}
        onSelectEvent={handleSelectEvent}
        onEventDrop={moveEvent}
        resizable
        onEventResize={resizeEvent}
        style={{ height: 500 }}
        className="h-screen w-[96%] ml-[2%] bg-white"
      />
      {showForm && (
        <ContentForm 
          onClose={handleFormClose}
          onSubmit={handleFormSubmit}
          initialData={selectedEvent || { start: selectedSlot.start, end: selectedSlot.end }}
        />
      )}
      {isViewPopupOpen && selectedContent && (
        <Popup content={selectedContent} onClose={() => setIsViewPopupOpen(false)} />
      )}
    </div>
  );
};

export default Scheduler;
