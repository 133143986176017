import React, { useState, useEffect } from 'react';


const Opacity = ({ canvas }) => {
  
  const [opacityValue, setOpacityValue] = useState(1); // Initial opacity value

  useEffect(() => {
    const fetchInitOpacity = () => {
      const activeObject = canvas?.getActiveObject();
      if (activeObject) {
        setOpacityValue(activeObject.opacity || 1); // Set initial opacity from active object if available
      }
    };

    if (canvas) {
      canvas.on("selection:created", fetchInitOpacity);
      canvas.on("selection:updated", fetchInitOpacity);
    }

    return () => {
      if (canvas) {
        canvas.off("selection:created", fetchInitOpacity);
        canvas.off("selection:updated", fetchInitOpacity);
      }
    };
  }, [canvas]);

  
  const changeOpacity = (value) => {
    const activeObject = canvas?.getActiveObject();
    if (activeObject) {
      activeObject.set('opacity', parseFloat(value)); // Ensure value is parsed as float
      canvas.renderAll();
      setOpacityValue(parseFloat(value)); // Update local state with new opacity value
    }
  };

  return (
    <div className="mt-4 flex flex-col items-center">
        <input
          className="w-56 ml-2"
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={opacityValue}
          onChange={(e) => changeOpacity(e.target.value)}
        />
    </div>
  );
};

export default Opacity;


