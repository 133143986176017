import SideBar from "./SideBar"
import SummaryCards from "./SummaryCards"
import Content from "./Content"


 const Home =()=>{

    return(
        <div className="flex">
            <SideBar/>
            <div className="flex flex-col w-full items-center bg-slate-100">
                <div className="h-20 "></div>
                <SummaryCards/>
                <Content/>
            </div>
        </div>
    );

}

export default Home;