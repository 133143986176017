import { fabric } from 'fabric';


 export const addHeadingText = (canvas)=> {
    if (canvas) {
        const text = new fabric.Textbox('Heading Text', {
          left: 50,
          top: 50,
          width: 200,
          fontSize: 25,
        });
        canvas.add(text);
      }
  };

  export const addSubHeadingText = (canvas)=> {
    if (canvas) {
        const text = new fabric.Textbox('Sub Heading Text', {
          left: 50,
          top: 100,
          width: 200,
          fontSize: 18,
        });
        canvas.add(text);
      }
  };

  export const addBodyText = (canvas)=> {
    if (canvas) {
        const text = new fabric.Textbox('Body Text', {
          left: 50,
          top: 150,
          width: 200,
          fontSize: 16,
        });
        canvas.add(text);
      }
  };

  // utils/TextUtils.js
  export const setFontFamily = (canvas, fontFamily) => {
    try {
      const activeObject = canvas.getActiveObject();
      if (activeObject && activeObject.type === 'textbox') {
        activeObject.set({ fontFamily });
        canvas.renderAll();
        canvas.fire('object:modified');
      }
    } catch (error) {
      console.error("Error setting font family:", error);
    }
  };
  
  export const setFontSize = (canvas, fontSize) => {
    try {
      const activeObject = canvas.getActiveObject();
      if (activeObject && activeObject.type === 'textbox') {
        activeObject.set({ fontSize: parseInt(fontSize, 10) });
        canvas.renderAll();
        canvas.fire('object:modified');
      }
    } catch (error) {
      console.error("Error setting font size:", error);
    }
  };
  
  export const setTextAlign = (canvas, textAlign) => {
    try {
      const activeObject = canvas.getActiveObject();
      if (activeObject && activeObject.type === 'textbox') {
        activeObject.set({ textAlign });
        canvas.renderAll();
        canvas.fire('object:modified');
      }
    } catch (error) {
      console.error("Error setting text alignment:", error);
    }
  };
  
  export const toggleBold = (canvas) => {
    try {
      const activeObject = canvas.getActiveObject();
      if (activeObject && activeObject.type === 'textbox') {
        const isBold = activeObject.fontWeight === 'bold';
        activeObject.set({ fontWeight: isBold ? 'normal' : 'bold' });
        canvas.renderAll();
        canvas.fire('object:modified');
      }
    } catch (error) {
      console.error("Error toggling bold:", error);
    }
  };
  
  export const toggleItalic = (canvas) => {
    try {
      const activeObject = canvas.getActiveObject();
      if (activeObject && activeObject.type === 'textbox') {
        const isItalic = activeObject.fontStyle === 'italic';
        activeObject.set({ fontStyle: isItalic ? 'normal' : 'italic' });
        canvas.renderAll();
        canvas.fire('object:modified');
      }
    } catch (error) {
      console.error("Error toggling italic:", error);
    }
  };
  
  export const toggleUnderline = (canvas) => {
    try {
      const activeObject = canvas.getActiveObject();
      if (activeObject && activeObject.type === 'textbox') {
        const isUnderline = activeObject.underline === true;
        activeObject.set({ underline: !isUnderline });
        canvas.renderAll();
        canvas.fire('object:modified');
      }
    } catch (error) {
      console.error("Error toggling underline:", error);
    }
  };
  
