export const postData = [
  {
    postId: "post1",
    user_id: "usr_002",
    content: "Excited to launch our new product!",
    media: [
      {
        type: "image",
        url: "https://socialplanner.io/blog/wp-content/uploads/2020/09/Free-Email-Marketing-Tools.jpg.webp",
      },
    ],
    designData: "{\"objects\":[],\"background\":\"\"}",
    //QuillContent : "html /// fbbfb"
    createdAt: "2024-07-07T12:00:00Z",
    updatedAt: "2024-07-07T12:00:00Z",
    status: "draft",
    platforms: ["twitter", "facebook"],
    tags: ["launch", "new product"],
    campaign_id: 1 // Added campaign_id
    //type : email,
    //email_type : regular or designer
    // if type email && regular , send quillContent
  },
  {
    postId: "post2",
    user_id: "usr_001",
    content: "Join our webinar on social media marketing! Join our webinar on social media marketing! Join our webinar on social media marketing! Join our webinar on social media marketing! Join our webinar on social media marketing! Join our webinar on social media marketing! Join our webinar on social media marketing! Join our webinar on social media marketing!",
    media: [
      {
        type: "video",
        url: "https://example.com/media/video1.mp4",
      },
    ],
    designData: "{\"objects\":[],\"background\":\"\"}",
    createdAt: "2024-07-05T10:30:00Z",
    updatedAt: "2024-07-05T10:30:00Z",
    status: "scheduled",
    platforms: ["linkedin", "facebook"],
    tags: ["webinar", "marketing"],
    campaign_id: 2 // Added campaign_id
  },
  {
    postId: "post3",
    user_id: "usr_001",
    content: "Happy Monday! Here are some tips to boost your productivity.",
    media: [],
    designData: "{\"objects\":[],\"background\":\"\"}",
    createdAt: "2024-07-03T08:45:00Z",
    updatedAt: "2024-07-03T08:45:00Z",
    status: "posted",
    platforms: ["instagram"],
    tags: ["monday motivation", "productivity"],
    campaign_id: 3 // Added campaign_id
  },
];

export const dummyImages = [
  { id: 1, name: 'Mountain Landscape', url: 'https://images.unsplash.com/photo-1506905925346-21bda4d32df4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80' },
  { id: 2, name: 'Beach Sunset', url: 'https://images.unsplash.com/photo-1507525428034-b723cf961d3e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2073&q=80' },
  { id: 3, name: 'Forest Path', url: 'https://images.unsplash.com/photo-1441974231531-c6227db76b6e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2071&q=80' },
  { id: 4, name: 'City Skyline', url: 'https://images.unsplash.com/photo-1477959858617-67f85cf4f1df?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2144&q=80' },
  { id: 5, name: 'Desert Dunes', url: 'https://images.unsplash.com/photo-1509316785289-025f5b846b35?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2076&q=80' },
  { id: 6, name: 'Snow-capped Mountains', url: 'https://images.unsplash.com/photo-1519681393784-d120267933ba?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80' },
  { id: 1, name: 'Mountain Landscape', url: 'https://images.unsplash.com/photo-1506905925346-21bda4d32df4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80' },
  { id: 2, name: 'Beach Sunset', url: 'https://images.unsplash.com/photo-1507525428034-b723cf961d3e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2073&q=80' },
  { id: 3, name: 'Forest Path', url: 'https://images.unsplash.com/photo-1441974231531-c6227db76b6e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2071&q=80' },
  { id: 4, name: 'City Skyline', url: 'https://images.unsplash.com/photo-1477959858617-67f85cf4f1df?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2144&q=80' },
  { id: 5, name: 'Desert Dunes', url: 'https://images.unsplash.com/photo-1509316785289-025f5b846b35?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2076&q=80' },
  { id: 6, name: 'Snow-capped Mountains', url: 'https://images.unsplash.com/photo-1519681393784-d120267933ba?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80' }
];

/*<section className="w-[96%] bg-white shadow-md" name="subtext">
                <div className="mt-4 ml-5 mb-4">
                  <label>Sub Text :</label>
                  <textarea
                  className="w-full h-20 overflow-y-auto border rounded p-2"
                  placeholder = "subtext"
                  value={textAreaValue}
                  onChange={handleTextareaChange}
                  ></textarea>
                </div>
            </section> */

  /*<section className="w-[96%] flex justify-between items-center mb-4" name="meta functions">
                <button
                onClick={handleBack}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700"
                >
                Back
                </button>
                <div>
                    <button
                        onClick={handleSave}
                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700 mr-2"
                    >
                        Save
                    </button>
                    <button
                        onClick={handlePublish}
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                    >
                        Publish
                    </button>
                </div>
                
            </section> */


/* <hr className="w-[96%] mb-4" />

            <section className="flex w-[96%] bg-white shadow-md" name="schedule">
                <label className="block text-gray-700 mb-2 mr-5 mt-6 ml-2">Status:</label>
                <div className="mb-4 mt-4">
                    <select
                    value={status}
                    onChange={handleStatusChange}
                    className="border rounded w-full py-2 px-3 text-gray-700"
                    disabled={status === "posted"}
                    >
                    <option value="draft">Draft</option>
                    <option value="scheduled">Scheduled</option>
                    </select>
                </div>

                {status === "scheduled" && (
                    <div className="flex items-center mb-4">
                    <label className="block text-gray-700 mb-2 mr-5 ml-[10%]">Schedule:</label>
                    <DatePicker
                        selected={scheduleDate}
                        onChange={(date) => setScheduleDate(date)}
                        showTimeSelect
                        dateFormat="Pp"
                        className="border rounded h-10 w-full py-2 px-3 text-gray-700"
                    />
                    </div>
                )}

                {status === "posted" && (
                    <div className="mb-4">
                    <p>Posted on: {new Date(content.createdAt).toLocaleString()}</p>
                    </div>
                )}
            </section> */
export const data = [
  {
    postId: "post1",
    user_id: "usr_002",
    content: "Excited to launch our new product!",
    media: [
      {
        type: "image",
        url: "https://example.com/media/image1.jpg",
      },
    ],
    designData: `{\"objects\":[],\"background\":"#ffffff"}`,
    createdAt: "2024-07-07T12:00:00Z",
    updatedAt: "2024-07-07T12:00:00Z",
    status: "draft",
    platforms: ["twitter", "facebook"],
    tags: ["launch", "new product"],
    campaign_id: 1 // Added campaign_id
  },
  {
    postId: "post2",
    user_id: "usr_001",
    content: "Join our webinar on social media marketing! Join our webinar on social media marketing! Join our webinar on social media marketing! Join our webinar on social media marketing! Join our webinar on social media marketing! Join our webinar on social media marketing! Join our webinar on social media marketing! Join our webinar on social media marketing!",
    media: [
      {
        type: "video",
        url: "https://example.com/media/video1.mp4",
      },
    ],
    designData: "{\"objects\":[],\"background\":\"\"}",
    createdAt: "2024-07-05T10:30:00Z",
    updatedAt: "2024-07-05T10:30:00Z",
    status: "scheduled",
    platforms: ["linkedin", "facebook"],
    tags: ["webinar", "marketing"],
    campaign_id: 2 // Added campaign_id
  },
  {
    postId: "post3",
    user_id: "usr_001",
    content: "Happy Monday! Here are some tips to boost your productivity.",
    media: [],
    designData: "{\"objects\":[],\"background\":\"\"}",
    createdAt: "2024-07-03T08:45:00Z",
    updatedAt: "2024-07-03T08:45:00Z",
    status: "posted",
    platforms: ["instagram"],
    tags: ["monday motivation", "productivity"],
    campaign_id: 3 // Added campaign_id
  },
];


export const contacts = [
  {
    id: 1,
    user_id: "usr_001",
    name: "John",
    surname: "Doe",
    email: "john.doe@example.com"
  },
  {
    id: 2,
    user_id: "usr_002",
    name: "Jane",
    surname: "Smith",
    email: "jane.smith@example.com"
  },
  {
    id: 3,
    user_id: "usr_001",
    name: "Alice",
    surname: "Johnson",
    email: "alice.johnson@example.com"
  },
  {
    id: 4,
    user_id: "usr_001",
    name: "Bob",
    surname: "Brown",
    email: "bob.brown@example.com"
  },
  {
    id: 5,
    user_id: "usr_002",
    name: "Charlie",
    surname: "Davis",
    email: "charlie.davis@example.com"
  },
  {
    id: 6,
    user_id: "usr_002",
    name: "Diana",
    surname: "Evans",
    email: "diana.evans@example.com"
  },
  {
    id: 7,
    user_id: "usr_002",
    name: "Edward",
    surname: "Garcia",
    email: "edward.garcia@example.com"
  },
  {
    id: 8,
    user_id: "usr_001",
    name: "Fiona",
    surname: "Harris",
    email: "fiona.harris@example.com"
  },
  {
    id: 9,
    user_id: "usr_001",
    name: "George",
    surname: "Martinez",
    email: "george.martinez@example.com"
  },
  {
    id: 10,
    user_id: "usr_002",
    name: "Hannah",
    surname: "Lopez",
    email: "hannah.lopez@example.com"
  }
];

export const events = [
  {
    id: 1,
    contact_id: 1,
    user_id: "usr_001",
    name: "Birthday",
    description: "John's Birthday",
    date: "2024-08-15",
    campaign_id: 1 // Added campaign_id
  },
  {
    id: 2,
    contact_id: 2,
    user_id: "usr_002",
    name: "Anniversary",
    description: "Jane's Work Anniversary",
    date: "2024-09-10",
    campaign_id: 2 // Added campaign_id
  },
  {
    id: 3,
    contact_id: 3,
    user_id: "usr_001",
    name: "Birthday",
    description: "Alice's Birthday",
    date: "2024-07-22",
    campaign_id: 3 // Added campaign_id
  },
  {
    id: 4,
    contact_id: 4,
    user_id: "usr_002",
    name: "Graduation",
    description: "Bob's Graduation Ceremony",
    date: "2024-05-30",
    campaign_id: 4 // Added campaign_id
  },
  {
    id: 5,
    contact_id: 5,
    user_id: "usr_001",
    name: "Birthday",
    description: "Charlie's Birthday",
    date: "2024-12-12",
    campaign_id: 5 // Added campaign_id
  },
  {
    id: 6,
    contact_id: 6,
    user_id: "usr_002",
    name: "Anniversary",
    description: "Diana's Wedding Anniversary",
    date: "2024-11-25",
    campaign_id: 1 // Added campaign_id
  },
  {
    id: 7,
    contact_id: 7,
    user_id: "usr_001",
    name: "Birthday",
    description: "Edward's Birthday",
    date: "2024-03-18",
    campaign_id: 2 // Added campaign_id
  },
  {
    id: 8,
    contact_id: 8,
    user_id: "usr_002",
    name: "Promotion",
    description: "Fiona's Promotion Ceremony",
    date: "2024-10-05",
    campaign_id: 3 // Added campaign_id
  },
  {
    id: 9,
    contact_id: 9,
    user_id: "usr_001",
    name: "Birthday",
    description: "George's Birthday",
    date: "2024-06-20",
    campaign_id: 4 // Added campaign_id
  },
  {
    id: 10,
    contact_id: 10,
    user_id: "usr_002",
    name: "Retirement",
    description: "Hannah's Retirement Party",
    date: "2024-04-15",
    campaign_id: 5 // Added campaign_id
  }
];

export const attribute_type = [
  {
    id: 1,
    attribute_name: "Birthday",
    user_id: "usr_001"
  },
  {
    id: 2,
    attribute_name: "Anniversary",
    user_id: "usr_002"
  },
  {
    id: 3,
    attribute_name: "Graduation",
    user_id: "usr_002"
  },
  {
    id: 4,
    attribute_name: "Promotion",
    user_id: "usr_002"
  },
  {
    id: 5,
    attribute_name: "Retirement",
    user_id: "usr_002"
  }
];

export const campaigns = [
  {
    id: 1,
    name: "Summer Sale",
    user_id: "usr_001",
    type : "manual"
  },
  {
    id: 2,
    name: "Back to School",
    user_id: "usr_002",
    type : "manual"
  },
  {
    id: 3,
    name: "Holiday Specials",
    user_id: "usr_001",
    type : "manual"
  },
  {
    id: 4,
    name: "New Year Bash",
    user_id: "usr_002",
    type : "manual"
  },
  {
    id: 5,
    name: "Birthdays",
    user_id: "usr_001",
    type : "automatic"
  }
];

export const groups = [
  {
    id: 1,
    group_name: "VIP",
    user_id: "usr_001"
  },
  {
    id: 2,
    group_name: "Regular",
    user_id: "usr_002"
  },
  {
    id: 3,
    group_name: "Prospects",
    user_id: "usr_001"
  },
  {
    id: 4,
    group_name: "Leads",
    user_id: "usr_002"
  }
];

export const group_contact_link = [
  {
    id: 1,
    group_id: 1,
    contact_id: 1
  },
  {
    id: 2,
    group_id: 2,
    contact_id: 2
  },
  {
    id: 3,
    group_id: 3,
    contact_id: 3
  },
  {
    id: 4,
    group_id: 4,
    contact_id: 4
  },
  {
    id: 5,
    group_id: 1,
    contact_id: 5
  },
  {
    id: 6,
    group_id: 2,
    contact_id: 6
  },
  {
    id: 7,
    group_id: 3,
    contact_id: 7
  },
  {
    id: 8,
    group_id: 4,
    contact_id: 8
  },
  {
    id: 9,
    group_id: 1,
    contact_id: 9
  },
  {
    id: 10,
    group_id: 2,
    contact_id: 10
  }
];
