import { fabric } from 'fabric';

export const smartGuide = ( canvas)=>{
    
let horizontalLine = null;
let verticalLine = null;

function drawLine(coords, isHorizontal) {
    const options = {
        stroke: 'red',
        strokeWidth: 1,
        selectable: false,
        evented: false,
    };

    return new fabric.Line(coords, options);
}

function removeLines() {
    if (horizontalLine) {
        canvas.remove(horizontalLine);
        horizontalLine = null;
    }
    if (verticalLine) {
        canvas.remove(verticalLine);
        verticalLine = null;
    }
}

canvas.on('object:moving', function (e) {
    const movingObject = e.target;
    const objects = canvas.getObjects();

    let showHorizontalLine = false;
    let showVerticalLine = false;

    objects.forEach((obj) => {
        if (obj === movingObject) return;

        const movingObjectBounds = movingObject.getBoundingRect();
        const objBounds = obj.getBoundingRect();

        // Detect horizontal alignment
        const isTopAligned = Math.abs(movingObjectBounds.top - objBounds.top) < 5;
        const isBottomAligned = Math.abs(movingObjectBounds.top + movingObjectBounds.height - objBounds.top - objBounds.height) < 5;
        
        if (isTopAligned || isBottomAligned) {
            showHorizontalLine = true;
            const yPosition = isTopAligned ? movingObjectBounds.top : movingObjectBounds.top + movingObjectBounds.height;
            if (!horizontalLine) {
                horizontalLine = drawLine([0, yPosition, canvas.width, yPosition], true);
                canvas.add(horizontalLine);
            } else {
                horizontalLine.set({ y1: yPosition, y2: yPosition });
            }
        }

        // Detect vertical alignment
        const isLeftAligned = Math.abs(movingObjectBounds.left - objBounds.left) < 5;
        const isRightAligned = Math.abs(movingObjectBounds.left + movingObjectBounds.width - objBounds.left - objBounds.width) < 5;

        if (isLeftAligned || isRightAligned) {
            showVerticalLine = true;
            const xPosition = isLeftAligned ? movingObjectBounds.left : movingObjectBounds.left + movingObjectBounds.width;
            if (!verticalLine) {
                verticalLine = drawLine([xPosition, 0, xPosition, canvas.height], false);
                canvas.add(verticalLine);
            } else {
                verticalLine.set({ x1: xPosition, x2: xPosition });
            }
        }
    });

    if (!showHorizontalLine && horizontalLine) {
        removeLines();
    }

    if (!showVerticalLine && verticalLine) {
        removeLines();
    }

    canvas.renderAll();
});

canvas.on('mouse:up', function () {
    removeLines();
    canvas.renderAll();
});
}