import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';


const Contacts = ({initialContactGroups, initialContacts , initialGroups }) => {
  const [groups, setGroups] = useState(initialGroups);
  const [contacts, setContacts] = useState(initialContacts);
  const [contactGroups, setContactGroups] = useState(initialContactGroups);
  const [newGroup, setNewGroup] = useState("");
  const [newContact, setNewContact] = useState({ name: "", surname: "", email: "" });
  const [selectedGroup, setSelectedGroup] = useState(1); // Default to "All"

  const addContact = () => {
    if (newContact.name && newContact.surname && newContact.email) {
      const newContactObj = {
        id: Math.max(...contacts.map(c => c.id)) + 1,
        user_id: "usr_001",
        ...newContact
      };
      setContacts([...contacts, newContactObj]);
      setNewContact({ name: "", surname: "", email: "" });
    }
  };

  const deleteContact = (id) => {
    setContacts(contacts.filter(contact => contact.id !== id));
    setContactGroups(contactGroups.filter(cg => cg.contactId !== id));
  };

  const toggleContactGroup = (contactId, groupId) => {
    const exists = contactGroups.some(cg => cg.contactId === contactId && cg.groupId === groupId);
    if (exists) {
      setContactGroups(contactGroups.filter(cg => !(cg.contactId === contactId && cg.groupId === groupId)));
    } else {
      setContactGroups([...contactGroups, { contactId, groupId }]);
    }
  };

  const getContactGroups = (contactId) => {
    return contactGroups
      .filter(cg => cg.contactId === contactId)
      .map(cg => groups.find(g => g.id === cg.groupId));
  };

  const filteredContacts = selectedGroup === 1 
    ? contacts 
    : contacts.filter(contact => 
        contactGroups.some(cg => cg.contactId === contact.id && cg.groupId === selectedGroup)
      );

  return (
    <div className="m-6">
        <h2 className="text-2xl font-semibold mb-4">Contacts</h2>
        <table className="w-full mb-6">
        <thead>
            <tr className="bg-gray-200">
            <th className="p-2 text-left">Name</th>
            <th className="p-2 text-left">Surname</th>
            <th className="p-2 text-left">Email</th>
            <th className="p-2 text-left">Groups</th>
            <th className="p-2 text-left">Add to Group</th>
            <th className="p-2 text-left">Action</th>
            </tr>
        </thead>
        <tbody>
            {filteredContacts.map(contact => (
            <tr key={contact.id} className="border-b">
                <td className="p-2">{contact.name}</td>
                <td className="p-2">{contact.surname}</td>
                <td className="p-2">{contact.email}</td>
                <td className="p-2">
                {getContactGroups(contact.id).length > 0 ? (
                    getContactGroups(contact.id).map(group => (
                    <span key={group.id} className="mr-2 bg-gray-200 px-2 py-1 rounded">
                        {group.group_name}
                        <button
                        onClick={() => toggleContactGroup(contact.id, group.id)}
                        className="ml-1 text-red-500 hover:text-red-700"
                        >
                        ×
                        </button>
                    </span>
                    ))
                ) : (
                    <span className="text-gray-500">No groups</span>
                )}
                </td>
                <td className="p-2">
                <select 
                    onChange={(e) => toggleContactGroup(contact.id, parseInt(e.target.value))}
                    className="p-1 border rounded"
                >
                    <option value="">Add to group</option>
                    {groups.filter(group => 
                    group.id !== 1 && !getContactGroups(contact.id).some(g => g.id === group.id)
                    ).map(group => (
                    <option key={group.id} value={group.id}>{group.group_name}</option>
                    ))}
                </select>
                </td>
                <td className="p-2">
                <button
                    onClick={() => deleteContact(contact.id)}
                    className="text-red-500 hover:text-red-700"
                >
                    Delete
                </button>
                </td>
            </tr>
            ))}
        </tbody>
        </table>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 ">
        <input 
            type="text" 
            value={newContact.name} 
            onChange={(e) => setNewContact({...newContact, name: e.target.value})} 
            placeholder="Name"
            className="p-2 border rounded"
        />
        <input 
            type="text" 
            value={newContact.surname} 
            onChange={(e) => setNewContact({...newContact, surname: e.target.value})} 
            placeholder="Surname"
            className="p-2 border rounded"
        />
        <input 
            type="email" 
            value={newContact.email} 
            onChange={(e) => setNewContact({...newContact, email: e.target.value})} 
            placeholder="Email"
            className="p-2 border rounded"
        />
        <button 
            onClick={addContact}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
            Add Contact
        </button>
        </div>
    </div>
  );
};

export default Contacts;