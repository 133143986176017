import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Editor from './editor/editor';
import Home from './app/Home';
import Library from "./app/Library"
import Calendar from "./app/Calendar"
import CampaignContent from "./app/campaignContent"
import ViewContent from "./app/ViewContent"
import ContactManager from "./app/Groups/contactManagement"
import TemplateLoad from "./app/TemplateLoad"

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/editor/:Id" element={<Editor />} />
          <Route path="/template-load" element={<TemplateLoad />} />
          <Route path="/campaign/content/:campaignId/:campaignType" element={<CampaignContent />} />
          <Route path="/library" element={<Library />} />
          <Route path="/groups/contact-management" element={<ContactManager/>} />
          <Route path="/view/:Id" element={<ViewContent />} />
          <Route path="/calendar" element={<Calendar />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;


