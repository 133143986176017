

import React from "react";
import { Link } from "react-router-dom";

const EmailCard = ({ email, onClick }) => {
  return (
    <div>
      <div className="m-4 p-4 bg-white shadow-md rounded" >
        {email.media.length > 0 && email.media[0].type === "image" && (
          <img src={email.media[0].url} alt="Post media" className="h-64 w-60" />
        )}
        <p className="mt-2 w-60">{email.subject}</p>
      </div>
      <Link to={`/view/${email.postId}`} className="text-blue-500">
          Edit
      </Link>
    </div>
  );
};

export default EmailCard;
