import React, { useState, useEffect, useMemo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageSelector from './ImageSelector';
import { dummyImages } from "./DummyData";


const QuillEditor = ({ value, onChange, height = '500px' }) => {
  const [editorValue, setEditorValue] = useState(value || '');
  const [showImageSelector, setShowImageSelector] = useState(false);

  useEffect(() => {
    setEditorValue(value || '');
  }, [value]);

  const handleChange = (content, delta, source, editor) => {
    setEditorValue(content);
    if (typeof onChange === 'function') {
      onChange(content);
    }
  };

  const imageHandler = () => {
    setShowImageSelector(true);
  };

  const handleImageSelect = (url) => {
    const editor = quillRef.current.getEditor();
    const range = editor.getSelection();
    editor.insertEmbed(range.index, 'image', url);
    setShowImageSelector(false);
  };

  const quillRef = React.useRef();

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['bold', 'italic', 'underline'],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        ['link', 'image'],
        ['clean'],
        ['image-resize-25', 'image-resize-50', 'image-resize-75', 'image-resize-100'],
      ],
      handlers: {
        image: imageHandler,
        'image-resize-25': () => resizeImage('25%'),
        'image-resize-50': () => resizeImage('50%'),
        'image-resize-75': () => resizeImage('75%'),
        'image-resize-100': () => resizeImage('100%'),
      }
    }
  }), []);

  const resizeImage = (size) => {
    const editor = quillRef.current.getEditor();
    const range = editor.getSelection();
    if (range) {
      const [image] = editor.getLeaf(range.index);
      if (image.domNode && image.domNode.tagName === 'IMG') {
        image.domNode.style.width = size;
        image.domNode.style.height = 'auto';
      }
    }
  };

  return (
    <div className="quill-editor" style={{ height }}>
      <ReactQuill
        ref={quillRef}
        value={editorValue}
        onChange={handleChange}
        modules={modules}
        theme="snow"
        style={{ height: '100%' }}
      />
      {showImageSelector && (
        <ImageSelector
          images={dummyImages}
          onSelect={handleImageSelect}
          onClose={() => setShowImageSelector(false)}
        />
      )}
    </div>
  );
};

export default QuillEditor;
