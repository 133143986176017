import { fabric } from 'fabric';

let scrollLeft = 0;
let scrollTop = 0;
let activeSection = 0;
let pageSize = 450;
let pageNumber = 1;
let backgroundColors = [];

export const setCanvasBackgroundColor = (color, page, canvas) => {

  // Add the new color at the specified page index
  backgroundColors.splice(page - 1, 0, color);
  console.log('background colors',backgroundColors)
  
  const totalPages = canvas.height / pageSize;

  // Create a gradient with color stops based on backgroundColors
  const gradient = new fabric.Gradient({
    type: 'linear',
    gradientUnits: 'pixels',
    coords: { x1: 0, y1: 0, x2: 0, y2: canvas.height }, // Vertical gradient across the entire canvas
    colorStops: backgroundColors.map((backgroundColor, index) => {
      const offsetStart = index / totalPages;   // Start of each color section
      const offsetEnd = (index + 1) / (totalPages); // End of the color section
      return [
        {
          offset: offsetStart,  // Start of the page
          color: backgroundColor
        },
        {
          offset: offsetEnd,   // End of the page
          color: backgroundColor
        }
      ];
    }).flat() // Flatten the array of color stops to avoid nested arrays
  });

  // Set the gradient as the canvas background
  canvas.backgroundColor = gradient;
  canvas.renderAll();
};
export const changeCanvasBackgroundColor = (color, page, canvas) => {

  // Add the new color at the specified page index
  backgroundColors[page-1] = color;
  console.log('background colors',backgroundColors)
  
  const totalPages = canvas.height / pageSize;

  // Create a gradient with color stops based on backgroundColors
  const gradient = new fabric.Gradient({
    type: 'linear',
    gradientUnits: 'pixels',
    coords: { x1: 0, y1: 0, x2: 0, y2: canvas.height }, // Vertical gradient across the entire canvas
    colorStops: backgroundColors.map((backgroundColor, index) => {
      const offsetStart = index / totalPages;   // Start of each color section
      const offsetEnd = (index + 1) / (totalPages); // End of the color section
      return [
        {
          offset: offsetStart,  // Start of the page
          color: backgroundColor
        },
        {
          offset: offsetEnd,   // End of the page
          color: backgroundColor
        }
      ];
    }).flat() // Flatten the array of color stops to avoid nested arrays
  });

  // Set the gradient as the canvas background
  canvas.backgroundColor = gradient;
  canvas.renderAll();
};
export const setCanvasBackgroundColorForDelete = (page, canvas) => {

  // Add the new color at the specified page index
  page = page - 1 ;
  backgroundColors.splice(page,1)
  pageNumber -=1
  console.log(backgroundColors);

  const totalPages = canvas.height / pageSize;

  // Create a gradient with color stops based on backgroundColors
  const gradient = new fabric.Gradient({
    type: 'linear',
    gradientUnits: 'pixels',
    coords: { x1: 0, y1: 0, x2: 0, y2: canvas.height }, // Vertical gradient across the entire canvas
    colorStops: backgroundColors.map((backgroundColor, index) => {
      const offsetStart = index / totalPages;   // Start of each color section
      const offsetEnd = (index + 1) / (totalPages); // End of the color section
      return [
        {
          offset: offsetStart,  // Start of the page
          color: backgroundColor
        },
        {
          offset: offsetEnd,   // End of the page
          color: backgroundColor
        }
      ];
    }).flat() // Flatten the array of color stops to avoid nested arrays
  });

  // Set the gradient as the canvas background
  canvas.backgroundColor = gradient;
  canvas.renderAll();
};
export const setScrollPositionForActiveSection = (left, top, canvas) => {

  scrollLeft = left;
  scrollTop = top;  
  let menu = document.getElementById('menu');
  activeSection = Math.round(scrollTop / pageSize) * pageSize;
  // Add or update the border around the active section
  updateActiveSectionBorder(activeSection, canvas);

  //hide the menu on scroll
  if (menu) {
    console.log("closed menu")
    menu.style.display = 'none';
  }

};

const updateActiveSectionBorder = (activeSection, canvas) => {

  const container = document.getElementById('canvas-container');
  // Check if the canvas container exists
  if (!container) {
    console.error('Canvas container not found.');
    return;  // Exit the function if the container is not found
  }

  // If the border already exists, update its position, otherwise create a new one
  let activeBorder = document.getElementById('active-section-border');
  if (!activeBorder) {
    // Create the border div
    activeBorder = document.createElement('div');
    activeBorder.id = 'active-section-border';
    activeBorder.style.position = 'absolute';
    activeBorder.style.border = '3px solid #02001f'; // Red border around the active section
    activeBorder.style.pointerEvents = 'none';  // Prevent the border from blocking canvas interaction
    container.appendChild(activeBorder);
  }

  // Update the border's position and size based on the active section
  activeBorder.style.width = `${canvas.getWidth() + 6}px`;  // Slightly larger than the canvas
  activeBorder.style.height = `${pageSize}px`;  // Height is the page size
  activeBorder.style.left = '-3px';  // Shift it outside the canvas by 3px
  activeBorder.style.top = `${activeSection}px`;  // Adjust based on scroll position

  // Create the button on the right side of the border
  let menuButton = document.getElementById('menu-button');
  let menu = document.getElementById('menu');

  if (!menuButton) {
    menuButton = document.createElement('button');
    menuButton.id = 'menu-button';
    menuButton.innerText = '⋮'; // Vertical ellipsis for the button
    menuButton.style.position = 'absolute';
    menuButton.style.right = '-30px';  // Position it to the right of the border
    container.appendChild(menuButton);

    // Create the menu element
    menu = document.createElement('div');
    menu.id = 'menu';
    menu.style.position = 'absolute';
    menu.style.display = 'none';  // Initially hidden
    menu.style.right = '0';
    menu.style.backgroundColor = '#e1eeef';
    menu.style.border = '1px solid #e1eeef';
    menu.style.padding = '10px';
    menu.style.boxShadow = '0 2px 10px rgba(0, 0, 0, 0.1)';

    // Create background color change option
    const changeColorOption = document.createElement('button');
    changeColorOption.innerText = 'Change Background Color';
    changeColorOption.onclick = () => {
      const colorInput = document.createElement('input');
      colorInput.type = 'color';
      colorInput.style.display = 'none'; // Hide the input element

      const page = Math.ceil((scrollTop + pageSize) / pageSize);
      const currentBackgroundColor = backgroundColors[page - 1]
      colorInput.value = currentBackgroundColor;
      // Listen for color input change
      colorInput.oninput = (e) => {
        const newColor = e.target.value;
        if (newColor) {
          const page = Math.ceil((scrollTop + pageSize) / pageSize);

          // console.log('Color to be changed on page', sectionToAddColor);
          changeCanvasBackgroundColor(newColor, page, canvas);
        }
      };

      // Trigger the color input click to show the color picker
      colorInput.click();
    };

    // Append the button to the menu
    menu.appendChild(changeColorOption);


    // Create delete page option
    const deletePageOption = document.createElement('button');
    deletePageOption.innerText = 'Delete Page';

    deletePageOption.onclick = () => {
      const sectionToDelete = Math.round(scrollTop / pageSize) * pageSize;
      removePage(canvas, sectionToDelete)
    };
    menu.appendChild(deletePageOption);

    // Append the menu to the container
    container.appendChild(menu);

    // Toggle menu visibility on button click
    menuButton.onclick = () => {
      menu.style.display = menu.style.display === 'none' ? 'block' : 'none';
    };

    // Hide the menu when clicking outside
    document.addEventListener('click', (event) => {
      if (event.target !== menuButton && !menu.contains(event.target)) {
        menu.style.display = 'none';
      }
    });
  }

  // Update the button and menu position based on the active section
  const sectionMiddle = activeSection + (pageSize/2);
  menuButton.style.top = `${sectionMiddle}px`;
  menu.style.top = `${sectionMiddle}px`;  // Keep the menu aligned in the middle
};

export const adjustCanvasHeight = (fabricCanvas) => {
  const objects = fabricCanvas.getObjects();
  const maxObjectHeight = Math.max(...objects.map(obj => obj.top + obj.height));

  // Calculate the number of pages needed
  const totalPages = Math.ceil(maxObjectHeight / 450);

  // Current canvas height before adjustment
  let currentCanvasHeight = fabricCanvas.getHeight();

  // Add separator and adjust height for each new page
  for (let i = Math.ceil(currentCanvasHeight / 450); i < totalPages; i++) {
    // Add 450px for the new page
    currentCanvasHeight += 450;
    fabricCanvas.setHeight(currentCanvasHeight);

    // Add a separator line at the bottom of the current content
    const separatorY = currentCanvasHeight - 450;
    const separator = new fabric.Line([0, separatorY, fabricCanvas.getWidth(), separatorY], {
      stroke: '#d1d1d1',
      strokeWidth: 3,
      selectable: false,
      excludeFromHTML: true,
      evented: true,
    });

    // Add the line to the canvas
    fabricCanvas.add(separator);

    // Attach event listener to show context menu on right-click
    separator.on('mousedown', (event) => {
      showContextMenu(event.e.clientX, event.e.clientY, fabricCanvas, separator);
    });

    // Attach listener to hide context menu when clicking elsewhere
    fabricCanvas.on('mouse:down', (event) => {
      hideContextMenu(); // Hide the menu when any other part of the canvas is clicked
    });

    // Optionally set background color for new page (if needed)
    setCanvasBackgroundColor('white', i + 1, fabricCanvas);
  }

  // Adjust objects if needed for multiple pages
  objects.forEach(obj => {
    if (obj.top > 450) {
      const pageShift = Math.floor(obj.top / 450) * 450;
      obj.top -= pageShift; // Move object to the correct page
    }
  });

  fabricCanvas.renderAll();
};
 
// Function to increase canvas height and add a separator line
export const increaseCanvasHeight = (canvasInstance ) => {
  if (canvasInstance) {
    
    const canvas = canvasInstance;
    const canvasHeight = canvas.getHeight();
    // Create a new line (separator) at the bottom of the current content
    const separator = new fabric.Line([0, canvasHeight, canvas.getWidth(), canvasHeight], {
      stroke: '#d1d1d1',  // Line color
      strokeWidth: 3,     // Thin line
      selectable: false,  // Ensure the line is not selectable
      excludeFromHTML: true,
      evented: true,      // Allow the line to listen to events even though it's not selectable
    });

    // Add the line to the canvas
    canvas.add(separator);

    const newHeight = canvasHeight + 450;
    pageNumber += 1; 
    canvas.setHeight(newHeight);
    canvas.renderAll();

    setCanvasBackgroundColor('white',pageNumber,canvas)
    // Attach event listener to show the context menu on right-click
    separator.on('mousedown', (event) => {
      showContextMenu(event.e.clientX, event.e.clientY, canvas, separator);
    });

    // Hide context menu on canvas or object click
    canvas.on('mouse:down', (event) => {
      hideContextMenu(); // Hide the menu when any other part of the canvas is clicked
    });

  }
};

export const addSeparator = (canvasInstance ) => {
  if (canvasInstance) {
    
    const canvas = canvasInstance;
    const canvasHeight = canvas.getHeight();
    // Create a new line (separator) at the bottom of the current content
    const separator = new fabric.Line([0, canvasHeight, canvas.getWidth(), canvasHeight], {
      stroke: '#d1d1d1',  // Line color
      strokeWidth: 3,     // Thin line
      selectable: false,  // Ensure the line is not selectable
      excludeFromHTML: true,
      evented: true,      // Allow the line to listen to events even though it's not selectable
    });

    // Add the line to the canvas
    canvas.add(separator);
    canvas.renderAll();

    setCanvasBackgroundColor('blue',pageNumber,canvas)
  
    separator.on('mousedown', (event) => {
      showContextMenu(event.e.clientX, event.e.clientY, canvas, separator);
    });

    // Hide context menu on canvas or object click
    canvas.on('mouse:down', (event) => {
      hideContextMenu(); // Hide the menu when any other part of the canvas is clicked
    });

  }
};
// Function to show the context menu at the specified position
const showContextMenu = (left, top, canvas, separator) => {
  hideContextMenu(); // Make sure to hide any existing menu

  const menu = document.createElement('div');
  menu.id = 'context-menu';
  menu.style.position = 'absolute';
  menu.style.left = `${left}px`;
  menu.style.top = `${top}px`;
  menu.style.backgroundColor = '#ffffff';
  menu.style.border = '1px solid #dddddd';
  menu.style.borderRadius = '4px';
  menu.style.boxShadow = '0px 2px 10px rgba(0, 0, 0, 0.1)';
  menu.style.padding = '10px';
  menu.style.zIndex = '1000';
  menu.style.fontFamily = 'Arial, sans-serif';
  menu.style.fontSize = '14px';
  menu.style.color = '#333';
  menu.style.minWidth = '160px';
  
  // Inner HTML for menu buttons
  menu.innerHTML = `
    <button id="add-page-between" style="
      background-color: #007bff;
      border: none;
      color: #ffffff;
      padding: 8px 12px;
      text-align: center;
      text-decoration: none;
      display: block;
      font-size: 14px;
      margin-bottom: 4px;
      cursor: pointer;
      border-radius: 4px;
      transition: background-color 0.3s ease;
    ">Add Page</button>
    
  `;
  document.body.appendChild(menu);

  document.getElementById('add-page-between').onclick = () => {
    addPageBetween(canvas, separator);
    hideContextMenu();
  };
  // document.getElementById('remove-bottom').onclick = () => {
  //   removeBottomPage(canvas, separator.top + 2);
  //   hideContextMenu();
  // };
  // document.getElementById('remove-upper').onclick = () => {
  //   removeUpperPage(canvas, separator.top);
  //   hideContextMenu();
  // };
};

// Function to hide the context menu
export const hideContextMenu = () => {
  const existingMenu = document.getElementById('context-menu');
  if (existingMenu) {
    document.body.removeChild(existingMenu); // Remove the menu from the DOM
  }
};

// Function to remove a page and shift other pages
const removeBottomPage = (canvas, startY) => {
  const pageHeight = pageSize; // Assuming each page height is 450
  let page = Math.ceil((startY + 40)/pageHeight)

  const newCanvasHeight = canvas.getHeight() - pageHeight;
  const objectsToRemove = canvas.getObjects().filter(obj => obj.top >= startY && obj.top < startY + pageHeight);
  
  // Remove those objects
  objectsToRemove.forEach(obj => canvas.remove(obj));

  // Move all objects below the removed page up
  const objectsToMove = canvas.getObjects().filter(obj => obj.top >= startY + pageHeight);
  objectsToMove.forEach(obj => {
    obj.top -= pageHeight; // Move objects up by the height of a page
    obj.setCoords(); // Update object coordinates
  });

  canvas.setHeight(newCanvasHeight);
  canvas.renderAll();
  setCanvasBackgroundColorForDelete(page,canvas)
};

const removeUpperPage = (canvas, lineY) => {
  const pageHeight = pageSize; // Assuming each page height is 450
  const canvasHeight = canvas.getHeight();
  let page = Math.ceil((lineY - 40)/pageHeight)

  // Calculate the top boundary of the page to be removed
  const startY = lineY - pageHeight;

  // Remove objects within the page above the line
  const objectsToRemove = canvas.getObjects().filter(obj => obj.top >= startY && obj.top < lineY);
  objectsToRemove.forEach(obj => canvas.remove(obj));

  // Move objects below the removed page up
  const objectsToMove = canvas.getObjects().filter(obj => obj.top >= lineY);
  objectsToMove.forEach(obj => {
    obj.top -= pageHeight; // Move objects up by the height of a page
    obj.setCoords(); // Update object coordinates
  });

  // Update canvas height
  const newCanvasHeight = canvasHeight - pageHeight;
  canvas.setHeight(newCanvasHeight);

  canvas.renderAll(); // Rerender the canvas
  setCanvasBackgroundColorForDelete(page,canvas);
};

const addPageAbove = (canvas, separator) => {
  const pageHeight = 450;
  const canvasHeight = canvas.getHeight();
  const separatorTop = separator.top;
  let page = Math.ceil((separatorTop - 40)/pageHeight)
  // Move the clicked separator and all objects below it down
  canvas.getObjects().forEach(obj => {
    if (obj.top >= separatorTop) {
      obj.set('top', obj.top + pageHeight);
      obj.setCoords();
    }
  });

  // Create a new separator at the top of the new page (where the clicked separator was)
  const newSeparator = new fabric.Line([0, separatorTop, canvas.getWidth(), separatorTop], {
    stroke: '#d1d1d1',
    strokeWidth: 3,
    selectable: false,
    evented: true,
    excludeFromHTML: true,
  });

  // Add the new separator and set up its event listener
  canvas.add(newSeparator);
  newSeparator.on('mousedown', (event) => {
    showContextMenu(event.e.clientX, event.e.clientY, canvas, newSeparator);
  });

  // Increase the canvas height
  canvas.setHeight(canvasHeight + pageHeight);
  canvas.renderAll();
  setCanvasBackgroundColor('red',page,canvas)
};

const addPageBetween = (canvas, separator) => {
  const pageHeight = 450;
  const canvasHeight = canvas.getHeight();
  const separatorTop = separator.top;
  let page = Math.ceil((separatorTop + 40)/pageHeight)

  // Move objects below the separator down
  canvas.getObjects().forEach(obj => {
    if (obj.top > separatorTop) {
      obj.set('top', obj.top + pageHeight);
      obj.setCoords();
    }
  });

  // Create a new separator at the bottom of the new page
  const newSeparator = new fabric.Line([0, separatorTop + pageHeight, canvas.getWidth(), separatorTop + pageHeight], {
    stroke: '#d1d1d1',
    strokeWidth: 3,
    selectable: false,
    evented: true,
    excludeFromHTML: true,
  });

  // Add the new separator and set up its event listener
  canvas.add(newSeparator);
  newSeparator.on('mousedown', (event) => {
    showContextMenu(event.e.clientX, event.e.clientY, canvas, newSeparator);
  });

  // Increase the canvas height
  pageNumber += 1;
  canvas.setHeight(canvasHeight + pageHeight);
  canvas.renderAll();
  setCanvasBackgroundColor('white',page,canvas)
};

const removePage = (canvas, startY) => {
  const pageHeight = pageSize; 
  const endY = startY + pageHeight;  // Calculate the bottom of the page to be removed
  let page = Math.ceil((startY + 40) / pageHeight);
  console.log('page number',page)

  // Calculate the new canvas height after the page is removed
  const newCanvasHeight = canvas.getHeight() - pageHeight;

  // Find and remove all objects within the boundaries of the page (between startY and endY)
  const objectsToRemove = canvas.getObjects().filter(obj => obj.top >= startY && obj.top < endY);
  objectsToRemove.forEach(obj => canvas.remove(obj));

  // Check if there are any objects below the removed page
  const objectsToMove = canvas.getObjects().filter(obj => obj.top >= endY);

  if (objectsToMove.length > 0) {
    
    objectsToMove.forEach(obj => {
      console.log('there are objects below')
      obj.top -= pageHeight;  // Move objects up by one page height
      obj.setCoords();  // Update the object's coordinates
    });

  }

  //hide the section border to avoid weird bahaviour on the canvas
  let container = document.getElementById('active-section-border');
  if(container){

    container.style.display = 'none';
  }
  
  canvas.setHeight(newCanvasHeight);
  // Render the canvas after changes
  canvas.renderAll();

  // display the border again after rendering the canvas
  container.style.display = 'block'

  //Set the canvas background color for the page being deleted
  setCanvasBackgroundColorForDelete(page, canvas);
};



  

  