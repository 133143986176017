import { useEffect, useState } from 'react';
import { BsArrow90DegLeft } from "react-icons/bs";
import { BsArrow90DegRight } from "react-icons/bs";

const UNDO_REDO = ({canvas })=>{

    const initialState = {
        "canvasState":[],
        "canvasStateIndex": -1
    }

    const [state , setState] = useState(initialState)
    //const [prevState, setPrevState] = useState(state)

    const saveState = () => {
        const jsonData = JSON.stringify(canvas.toJSON());
        //console.log("json", jsonData);

        setState(prevState => {
            const newCanvasState = [...prevState.canvasState.slice(0, prevState.currentStateIndex + 1), jsonData];
            //console.log("new canvas state", newCanvasState);

            return {
                ...prevState,
                canvasState: newCanvasState,
                currentStateIndex: newCanvasState.length - 1,
            };
        });
    };

    const handleCanvasEvent = () => saveState();

    useEffect(() => {
        if (!canvas) return;

        canvas.on('object:modified', handleCanvasEvent);
        canvas.on('object:added', handleCanvasEvent);
        canvas.on('object:removed', handleCanvasEvent);

        saveState();

        return () => {
            canvas.off('object:modified', handleCanvasEvent);
            canvas.off('object:added', handleCanvasEvent);
            canvas.off('object:removed', handleCanvasEvent);
        };
    }, [canvas]);

    useEffect(() => {
        //console.log("state saved", state);
    }, [state]);

    const undoChange = () => {
        if (state.currentStateIndex > 0) {
            const prevIndex = state.currentStateIndex - 1;
            applyChangeToCanvas(state.canvasState[prevIndex]);
            setState({ ...state, currentStateIndex: prevIndex });
        }
    };

    const redoChange = () => {
        if (state.currentStateIndex < state.canvasState.length - 1) {
            const nextIndex = state.currentStateIndex + 1;
            applyChangeToCanvas(state.canvasState[nextIndex]);
            setState({ ...state, currentStateIndex: nextIndex });
        }
    };

    const applyChangeToCanvas = (change) => {
        if (change) {
            canvas.loadFromJSON(change, () => {
                //console.log(" re rendered")
                canvas.renderAll();
            });
        }
    };

    return(
        <div className="flex mt-2">
            <BsArrow90DegLeft onClick={undoChange} disabled={state.currentStateIndex <= 0} className="text-black shadow-sm ml-2 mr-1 w-10 h-6" />

            <BsArrow90DegRight onClick={redoChange} disabled={state.currentStateIndex >= state.canvasState.length - 1} className="text-black shadow-sm  mr-2 w-10 h-6" />
        </div>
    );
};

export default UNDO_REDO;