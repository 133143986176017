import { fabric } from 'fabric';
// globalState.js
let scrollLeft = 0;
let scrollTop = 0;

export const setScrollPosition = (left, top) => {
  scrollLeft = left ;
  scrollTop = top;
};

export const getScrollPosition = () => ({
  left: scrollLeft,
  top: scrollTop,
});

const createShapeFromPath = (canvas, pathData, options = {}) => {
  const path = new fabric.Path(pathData, {
    left: 100 + scrollLeft,
    top: 100 + scrollTop,
    fill: '#aeaeb9',
    ...options
  });
  canvas.add(path);
  canvas.renderAll();
};

export const addRectangle = (canvas) => {
  const pathData = 'M 0 0 H 60 V 70 H 0 Z';
  createShapeFromPath(canvas, pathData);
};

export const addCircle = (canvas) => {
  const pathData = 'M 30 30 m -30, 0 a 30,30 0 1,0 60,0 a 30,30 0 1,0 -60,0';
  createShapeFromPath(canvas, pathData);
};

export const addTriangle = (canvas) => {
  const pathData = 'M 30 0 L 60 70 L 0 70 Z';
  createShapeFromPath(canvas, pathData);
};

export const addEllipse = (canvas) => {
  const pathData = 'M 50 30 m -50, 0 a 50,30 0 1,0 100,0 a 50,30 0 1,0 -100,0';
  createShapeFromPath(canvas, pathData);
};

export const addLine = (canvas) => {
  const pathData = 'M 0 0 L 150 100';
  createShapeFromPath(canvas, pathData, { stroke: '#aeaeb9', strokeWidth: 2, fill: '' });
};

export const addPolygon = (canvas) => {
  const pathData = 'M 25 0 L 50 50 L 0 50 Z';
  createShapeFromPath(canvas, pathData);
};

export const addStar = (canvas) => {
  const pathData = 'M 50 0 L 61 35 L 98 35 L 68 57 L 79 91 L 50 70 L 21 91 L 32 57 L 2 35 L 39 35 Z';
  createShapeFromPath(canvas, pathData);
};

export const addHexagon = (canvas) => {
  const pathData = 'M 50 0 L 100 0 L 125 43.3 L 100 86.6 L 50 86.6 L 25 43.3 Z';
  createShapeFromPath(canvas, pathData);
};

export const addHeart = (canvas) => {
  const pathData = `
    M 50 30 
    C 50 15, 70 15, 70 30 
    C 70 45, 50 55, 50 70 
    C 50 55, 30 45, 30 30 
    C 30 15, 50 15, 50 30 
    Z
  `;
  
  createShapeFromPath(canvas, pathData);
};

export const addArrow = (canvas) => {
  const pathData = 'M 0 10 L 10 0 L 20 10 L 10 20 Z';
  createShapeFromPath(canvas, pathData, { left: 550, top: 250, scaleX: 2, scaleY: 2 });
};

