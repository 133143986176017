import React from 'react';
import { IoCopyOutline } from "react-icons/io5";

const DuplicateElement = ({ canvas }) => {
  const duplicateElement = () => {
    const activeObject = canvas?.getActiveObject();
    if (activeObject) {
      activeObject.clone((clonedObj) => {
        canvas.discardActiveObject();
        clonedObj.set({
          left: clonedObj.left + 10,
          top: clonedObj.top + 10,
          evented: true,
        });
        canvas.add(clonedObj);
        canvas.setActiveObject(clonedObj);
        canvas.renderAll();
      });
    }
  };

  return (
    <div className="mt-2">
      <IoCopyOutline onClick={duplicateElement} className="text-black shadow-sm  w-10 h-6" />
    </div>
  );
};

export default DuplicateElement;
