import { GoArrowUpRight } from "react-icons/go";
import { GoArrowDownLeft } from "react-icons/go";

const SummaryCards =()=>{

    return(
        <div className="flex  justify-evenly w-[98%] ">

            <div className="h-32 w-[30%] bg-white">
                <div className="ml-4 mt-4">
                    <div className="flex text-black">
                        <p className="font-bold uppercase text-sm">Most efficient day</p>
                        <div className="ml-32">...</div>
                    </div>
                    <p className="font-bold uppercase text-xl mt-2 mb-2">Tuesday</p>
                    <p className="flex">
                        <GoArrowUpRight className="mt-1 mr-1 text-green-600" />
                        <span className="mr-1 text-green-600">2.5%</span> 
                        <span className="ml-2 mb-1 text-gray-500">engagements</span>
                    </p>
                </div>
            </div>

            <div className="h-32 w-[30%] bg-white">
                <div className="ml-4 mt-4">
                    <div className="flex text-black">
                        <p className="font-bold uppercase text-sm">audience reached</p>
                        <div className="ml-32">...</div>
                    </div>
                    <p className="font-bold uppercase text-xl mt-2 mb-2">41 520</p>
                    <p className="flex ">
                        <GoArrowDownLeft className="mt-1 mr-1 text-red-600" />
                        <span className="mr-1 text-red-600">3.5%</span> 
                        <span className="ml-2 mb-1 text-gray-500">since last week</span>
                    </p>
                </div>
            </div>

            <div className="h-32 w-[30%] bg-white">
            <div className="ml-4 mt-4">
                <div className="flex text-black">
                    <p className="font-bold uppercase text-sm">Engagements</p>
                    <div className="ml-44">...</div>
                </div>
                <p className="flex text-sm">
                    <span className="mr-1 ">Likes</span> 
                    <span className="ml-[150px] mb-1 text-gray-500 ">20 300</span>
                </p>
                <p className="flex text-sm">
                    <span className="mr-1 ">Comments</span> 
                    <span className="ml-[112px] mb-1 text-gray-500">1 561</span>
                </p>
                <p className="flex text-sm">
                    <span className="mr-1 ">Emails Opened</span> 
                    <span className="ml-[90px] mb-2 text-gray-500">300</span>
                </p>
            </div>
        </div>
        </div>
    );

}

export default SummaryCards;