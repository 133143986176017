import { } from "../utils/uploadsUtils"
import { fabric } from 'fabric';

export default function Uploader({canvas}){

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
    
        reader.onload = (e) => {
          fabric.Image.fromURL(e.target.result, (img) => {
            img.scaleToWidth(200);
            img.scaleToHeight(200);
            //img.set({ selectable: false, evented: false });
            canvas.add(img);
            canvas.bringToFront(img);
            canvas.renderAll();
          });
        };
    
        reader.readAsDataURL(file);
      };

   return(
       <div>
           <div style={{ margin: '10px 0' }} className="bg-slate-50 h-screen" >
           <input type="file" onChange={handleImageUpload} />
           </div>
       </div>
   );
}