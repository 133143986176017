import { useState ,useMemo} from 'react';
import { LuShapes } from "react-icons/lu";
import { RxText } from "react-icons/rx";
import { IoCloudUploadOutline } from "react-icons/io5";
import { LuLayoutTemplate } from "react-icons/lu";
import { MdOutlineDraw } from "react-icons/md";
import { FaRegFolderClosed } from "react-icons/fa6";
import { IoIosArrowBack } from "react-icons/io";
import Text from "./components/Text";
import Shape from "./components/Shape";
import Uploader from "./components/Uploads"
import Drawing from "./components/drawing"
import Templates from "./components/templates"

export default function Sidebar({canvas , toggleSideBar}) {
  const [sidebarItem, setSidebarItem] = useState("closed");

  const deselectActiveObject = () => {
    if (canvas) {
      canvas.discardActiveObject();
      canvas.renderAll();
    }
  };
  const renderSidebarContent = useMemo(() => {
    //one of the most functions in the code, if you want to understand it
    //comment the function and test the editor.....LOL
    deselectActiveObject();
    switch (sidebarItem) {
      case "templates":
        return <Templates canvas={canvas} />;
      case "text":
        return <Text canvas={canvas} />;
      case "shapes":
        return <Shape canvas={canvas} />;
      case "uploads":
        return <Uploader canvas={canvas} />;
      case "drawing":
        return <Drawing canvas={canvas} />;
      case "chart":
        return <FaRegFolderClosed className="h-10 text-slate-300" />;
      default:
        return <div className="p-4">Select an item from the sidebar.</div>;
    }
  }, [canvas, sidebarItem]);

  return (
    <div className="flex h-[91vh]">
      <div className="bg-white w-16 h-[91vh] flex flex-col justify-evenly">
        <div className="flex flex-col mb-auto items-center cursor-pointer" onClick={() =>{ setSidebarItem("templates"); toggleSideBar("open") }}>
          <LuLayoutTemplate size={40} className="h-10 text-slate-700"  />
          <p className=" font-sans text-xs flex justify-center ">Templates</p>
        </div>
        <div className="flex flex-col mb-auto items-center cursor-pointer" onClick={() => {setSidebarItem("text"); toggleSideBar("open")}}>
          <RxText size={40} className="h-10 text-slate-700"  />
          <p className=" font-sans text-xs flex justify-center ">Text</p>
        </div>
        <div className="flex flex-col mb-auto  items-center cursor-pointer" onClick={() =>{ setSidebarItem("shapes");toggleSideBar("open")}}>
          <LuShapes size={40} className="h-10 text-slate-700 "  />
          <p className=" font-sans text-xs flex justify-center ">Shapes</p>
        </div>
        <div className="flex flex-col mb-auto items-center cursor-pointer" onClick={() => {setSidebarItem("uploads");toggleSideBar("open")}} >
          <IoCloudUploadOutline size={40} className="h-10 text-slate-700" />
          <p className=" font-sans text-xs flex justify-center ">Uploads</p>
          </div>
        <div className="flex flex-col mb-auto items-center cursor-pointer" onClick={() => {setSidebarItem("drawing");toggleSideBar("open")}}>
          <MdOutlineDraw size={40} className="h-10 text-slate-700"  />
          <p className=" font-sans text-xs flex justify-center ">Draw</p>
        </div>
        <div className="flex flex-col mb-auto items-center cursor-pointer" onClick={() => setSidebarItem("chart")}>
          <FaRegFolderClosed size={40} className="h-10 text-slate-700"  />
          <p className=" font-sans text-xs flex justify-center ">Projects</p>
        </div>
      </div>

      {sidebarItem !== "closed" && (
        <div className="bg-white w-96">
          <div className="w-full h-screen">
            {renderSidebarContent}
          </div>
        </div>
      )}
      {sidebarItem !=="closed" && (
      <div className="flex justify-end items-center h-screen ">
        <IoIosArrowBack onClick={() =>{ setSidebarItem("closed"); toggleSideBar("closed")}} size={25} color={"white"} className=" w-4 h-16 bg-slate-700 rounded-r-2xl"  />
      </div> )}
    </div>
  );
}

