import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';


const ContactsImport = ({initialContactGroups, initialContacts , initialGroups }) => {
  const [groups, setGroups] = useState(initialGroups);
  const [contacts, setContacts] = useState(initialContacts);
  const [contactGroups, setContactGroups] = useState(initialContactGroups);
  const [newGroup, setNewGroup] = useState("");
  const [newContact, setNewContact] = useState({ name: "", surname: "", email: "" });
  const [selectedGroup, setSelectedGroup] = useState(1); // Default to "All"

  const importExcel = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const bstr = event.target.result;
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      
      const newContacts = data.slice(1).map((row, index) => ({
        id: Math.max(...contacts.map(c => c.id)) + index + 1,
        user_id: "usr_001",
        name: row[0],
        surname: row[1],
        email: row[2],
      }));
      
      setContacts([...contacts, ...newContacts]);

      // Assuming the Excel file has a column for group IDs (comma-separated)
      const newContactGroups = data.slice(1).flatMap((row, index) => {
        const contactId = Math.max(...contacts.map(c => c.id)) + index + 1;
        const groupIds = row[3] ? row[3].split(',').map(id => parseInt(id.trim())) : [];
        return groupIds.map(groupId => ({ contactId, groupId }));
      });

      setContactGroups([...contactGroups, ...newContactGroups]);
    };
    reader.readAsBinaryString(file);
  };


  return (
    <div>
        <h2 className="text-2xl font-semibold ml-6">Import Contacts</h2>
        <input 
        type="file" 
        accept=".xlsx, .xls" 
        onChange={importExcel}
        className="m-6"
        />
    </div>

  );
};

export default ContactsImport;