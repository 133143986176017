import {addRectangle,
     addCircle,
     addEllipse,addHeart,
     addHexagon,
     addLine,
     addStar,
     addTriangle
    } from "../utils/shapesUtils"
import { MdRectangle } from "react-icons/md";
import { FaCircle } from "react-icons/fa";
import { IoTriangle } from "react-icons/io5";
import { TbOvalVerticalFilled } from "react-icons/tb";
import { GiStraightPipe } from "react-icons/gi";
import { FaStar } from "react-icons/fa";
import { MdHexagon } from "react-icons/md";
import { FaHeart } from "react-icons/fa";


export default function shape({canvas}){
    return(
        <div>
            <div style={{ margin: '10px 0' }} className="bg-slate-50 h-screen">
                <br/>
                <div className="flex justify-evenly">
                    <MdRectangle size={50} color={'#aeaeb9'} onClick={()=> addRectangle(canvas)} className="cursor-pointer"/>

                    <FaCircle onClick={()=> addCircle(canvas)} className="cursor-pointer" size={50} color={'#aeaeb9'} />

                    <IoTriangle onClick={()=> addTriangle(canvas)} className="cursor-pointer" size={50} color={'#aeaeb9'} />

                    <TbOvalVerticalFilled onClick={()=> addEllipse(canvas)} className="cursor-pointer" size={50} color={'#aeaeb9'}/>
                </div>
                
                <div className="flex justify-evenly mt-5">
                    <GiStraightPipe onClick={()=> addLine(canvas)} className="cursor-pointer" size={50} color={'#aeaeb9'}/>

                    <FaStar onClick={()=> addStar(canvas)} className="cursor-pointer" size={50} color={'#aeaeb9'}/>

                    <MdHexagon onClick={()=> addHexagon(canvas)} className="cursor-pointer" size={50} color={'#aeaeb9'}/>

                    <FaHeart onClick={()=> addHeart(canvas)}className="cursor-pointer" size={50} color={'#aeaeb9'}/>
                </div>
                
            </div>
        </div>
    );
}