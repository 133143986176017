import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import SideBar from "../SideBar"
import Groups from "./groups"
import Contacts from "./contacts"
import Import from "./import"

const initialGroups = [
  { id: 1, group_name: "All", user_id: "usr_001" },
  { id: 2, group_name: "VIP", user_id: "usr_001" },
  { id: 3, group_name: "Regular", user_id: "usr_001" },
  { id: 4, group_name: "Prospects", user_id: "usr_001" },
];

const initialContacts = [
  { id: 1, user_id: "usr_001", name: "John", surname: "Doe", email: "john.doe@example.com" },
  { id: 2, user_id: "usr_001", name: "Jane", surname: "Smith", email: "jane.smith@example.com" },
  { id: 3, user_id: "usr_001", name: "Alice", surname: "Johnson", email: "alice@example.com" },
];

const initialContactGroups = [
  { contactId: 1, groupId: 2 },
  { contactId: 1, groupId: 3 },
  { contactId: 2, groupId: 3 },
  { contactId: 3, groupId: 4 },
];

const ContactManager = () => {
  const [groups, setGroups] = useState(initialGroups);
  const [contacts, setContacts] = useState(initialContacts);
  const [contactGroups, setContactGroups] = useState(initialContactGroups);
  const [newGroup, setNewGroup] = useState("");
  const [newContact, setNewContact] = useState({ name: "", surname: "", email: "" });
  const [selectedGroup, setSelectedGroup] = useState(1); // Default to "All"

  const addGroup = () => {
    if (newGroup) {
      const newGroupObj = {
        id: Math.max(...groups.map(g => g.id)) + 1,
        group_name: newGroup,
        user_id: "usr_001"
      };
      setGroups([...groups, newGroupObj]);
      setNewGroup("");
    }
  };

  const deleteGroup = (id) => {
    if (id === 1) return; // Prevent deleting "All" group
    setGroups(groups.filter(group => group.id !== id));
    setContactGroups(contactGroups.filter(cg => cg.groupId !== id));
  };

  const addContact = () => {
    if (newContact.name && newContact.surname && newContact.email) {
      const newContactObj = {
        id: Math.max(...contacts.map(c => c.id)) + 1,
        user_id: "usr_001",
        ...newContact
      };
      setContacts([...contacts, newContactObj]);
      setNewContact({ name: "", surname: "", email: "" });
    }
  };

  const deleteContact = (id) => {
    setContacts(contacts.filter(contact => contact.id !== id));
    setContactGroups(contactGroups.filter(cg => cg.contactId !== id));
  };

  const toggleContactGroup = (contactId, groupId) => {
    const exists = contactGroups.some(cg => cg.contactId === contactId && cg.groupId === groupId);
    if (exists) {
      setContactGroups(contactGroups.filter(cg => !(cg.contactId === contactId && cg.groupId === groupId)));
    } else {
      setContactGroups([...contactGroups, { contactId, groupId }]);
    }
  };

  const getContactGroups = (contactId) => {
    return contactGroups
      .filter(cg => cg.contactId === contactId)
      .map(cg => groups.find(g => g.id === cg.groupId));
  };

  const importExcel = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const bstr = event.target.result;
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      
      const newContacts = data.slice(1).map((row, index) => ({
        id: Math.max(...contacts.map(c => c.id)) + index + 1,
        user_id: "usr_001",
        name: row[0],
        surname: row[1],
        email: row[2],
      }));
      
      setContacts([...contacts, ...newContacts]);

      // Assuming the Excel file has a column for group IDs (comma-separated)
      const newContactGroups = data.slice(1).flatMap((row, index) => {
        const contactId = Math.max(...contacts.map(c => c.id)) + index + 1;
        const groupIds = row[3] ? row[3].split(',').map(id => parseInt(id.trim())) : [];
        return groupIds.map(groupId => ({ contactId, groupId }));
      });

      setContactGroups([...contactGroups, ...newContactGroups]);
    };
    reader.readAsBinaryString(file);
  };

  const filteredContacts = selectedGroup === 1 
    ? contacts 
    : contacts.filter(contact => 
        contactGroups.some(cg => cg.contactId === contact.id && cg.groupId === selectedGroup)
      );

  return (
    <div className="flex ">
        <SideBar/>
        <div className="container mx-auto p-6 bg-slate-100">

        <div className=" h-16"></div>
        
            <div className="bg-white">
            <Groups initialContactGroups={initialContactGroups} initialContacts={initialContacts} initialGroups={initialGroups}/>
            <Contacts initialContactGroups={initialContactGroups} initialContacts={initialContacts} initialGroups={initialGroups}/>
            <Import initialContactGroups={initialContactGroups} initialContacts={initialContacts} initialGroups={initialGroups}/>
            </div>
        </div>
    </div>
  );
};

export default ContactManager;