import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { postData, groups, contacts } from "./DummyData";
import SideBar from "./SideBar";
import EmailView from "./emailView"

const majorPlatforms = ["Facebook", "Twitter", "Instagram", "LinkedIn", "Pinterest"];

const ViewContent = () => {
  const { Id } = useParams();
  const [content, setContent] = useState({});
  const [textAreaValue, setTextAreaValue] = useState("");
  const [subject, setSubject] = useState("");
  const [scheduleDate, setScheduleDate] = useState(null);
  const [platforms, setPlatforms] = useState([]);
  const [newPlatform, setNewPlatform] = useState("");
  const [status, setStatus] = useState("");
  const [contactList, setContactList] = useState([]);
  const [allContactsAndGroups, setAllContactsAndGroups] = useState([]);

  useEffect(() => {
    // Combine all contact emails and group names into one list
    const contactsEmails = contacts.map(contact => ({ type: "contact", value: contact.email }));
    const groupsNames = groups.map(group => ({ type: "group", value: group.group_name }));
    const combinedList = [...contactsEmails, ...groupsNames];

    setAllContactsAndGroups(combinedList);
  }, []);

  useEffect(() => {
    const data = postData.find(data => data.postId === Id);
    if (data) {
      setContent(data);
      setTextAreaValue(data.content);
      setSubject(data.subject);
      setPlatforms(data.platforms);
      setStatus(data.status);
    }
  }, [Id]);

  const handleContactList = (e) => {
    if (e.target.value && !contactList.includes(e.target.value)) {
      setContactList([...contactList, e.target.value]);
    }
  };

  const handleRemoveContact = (contactToRemove) => {
    setContactList(contactList.filter(contact => contact !== contactToRemove));
  };

  const handleTextareaChange = (e) => {
    setTextAreaValue(e.target.value.slice(0, 280));
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value.slice(0, 60));
  };

  const handleAddPlatform = () => {
    if (newPlatform && !platforms.includes(newPlatform) && majorPlatforms.includes(newPlatform)) {
      setPlatforms([...platforms, newPlatform]);
      setNewPlatform("");
    }
  };

  const handleRemovePlatform = (platformToRemove) => {
    setPlatforms(platforms.filter(platform => platform !== platformToRemove));
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  return (
    <div className="flex items-center justify-center w-full ">
      <SideBar/>
      <div className="w-full ">
        <EmailView/>
      </div>
    </div>
  );
};

export default ViewContent;





