import  { useState } from "react";
import { Link } from "react-router-dom";
import { data , campaigns} from "./DummyData";

const Campaign = () => {

  return (
    <div className="flex flex-col w-full items-center bg-slate-100">
        <div className="h-20"></div>
        <div className="flex flex-wrap">
        { campaigns.map((campaign) => (
            <Link to={`/campaign/content/${campaign.id}/${campaign.type}`}>
            <div className=" w-60 h-60 m-4 p-4 bg-white shadow-md rounded">
            <p>{campaign.name} </p>
            <p>{campaign.type} </p>
            </div>
            </Link>
        ))
        }
        </div>
    </div>
  );
};

export default Campaign;
