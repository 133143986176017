import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';


const Groups = ({initialContactGroups, initialContacts , initialGroups }) => {
  const [groups, setGroups] = useState(initialGroups);
  const [contactGroups, setContactGroups] = useState(initialContactGroups);
  const [newGroup, setNewGroup] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(1); // Default to "All"

  const addGroup = () => {
    if (newGroup) {
      const newGroupObj = {
        id: Math.max(...groups.map(g => g.id)) + 1,
        group_name: newGroup,
        user_id: "usr_001"
      };
      setGroups([...groups, newGroupObj]);
      setNewGroup("");
    }
  };

  const deleteGroup = (id) => {
    if (id === 1) return; // Prevent deleting "All" group
    setGroups(groups.filter(group => group.id !== id));
    setContactGroups(contactGroups.filter(cg => cg.groupId !== id));
  };

  const toggleContactGroup = (contactId, groupId) => {
    const exists = contactGroups.some(cg => cg.contactId === contactId && cg.groupId === groupId);
    if (exists) {
      setContactGroups(contactGroups.filter(cg => !(cg.contactId === contactId && cg.groupId === groupId)));
    } else {
      setContactGroups([...contactGroups, { contactId, groupId }]);
    }
  };


  return (

        <div className="m-6 ">
            <h2 className="text-2xl font-semibold mb-4">Groups</h2>
            <div className="flex flex-wrap gap-2 mb-4">
            {groups.map(group => (
                <button
                key={group.id}
                onClick={() => setSelectedGroup(group.id)}
                className={`px-4 py-2 rounded ${selectedGroup === group.id ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                >
                {group.group_name}
                {group.id !== 1 && (
                    <span 
                    className="ml-2 text-red-500 cursor-pointer"
                    onClick={(e) => {
                        e.stopPropagation();
                        deleteGroup(group.id);
                    }}
                    >
                    ×
                    </span>
                )}
                </button>
            ))}
            </div>
            <div className="flex ">
            <input 
                type="text" 
                value={newGroup} 
                onChange={(e) => setNewGroup(e.target.value)} 
                placeholder="New Group Name"
                className="flex-grow mr-2 p-2 border rounded"
            />
            <button 
                onClick={addGroup}
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
            >
                Add Group
            </button>
            </div>
        </div>
  );
};

export default Groups;