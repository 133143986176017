import React, { useEffect, useState } from 'react';
import { addTemplateToCanvas, getAllTemplates, getAllComponents, addComponentToCanvas,} from '../utils/templateUtils';

const TemplateCard = ({ item, onClick }) => (
  <div 
    className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer hover:shadow-lg transition-shadow"
    onClick={onClick}
  >
    <img src={item.thumbnail} alt={item.name} className="w-full h-24 object-cover" />
    <div className="p-4">
      <h3 className="font-bold text-lg mb-1">{item.name}</h3>
      <p className="text-sm text-gray-600">{item.description}</p>
    </div>
  </div>
);

export default function TemplatesAndComponents({ canvas }) {
  const [templates, setTemplates] = useState([]);
  const [components, setComponents] = useState([]);
  const [activeTab, setActiveTab] = useState('components'); // Set default tab to 'components'

  useEffect(() => {
    getAllTemplates().then(setTemplates);
    getAllComponents().then(setComponents);
  }, []);

  const handleItemClick = (itemId, isComponent = false) => {
    if (isComponent) {
      addComponentToCanvas(canvas, itemId);
    } else {
      addTemplateToCanvas(canvas, itemId);
    }
  };

  return (
    <div className="bg-slate-50 p-4">
      <h2 className="text-2xl font-bold mb-4">Website Builder</h2>
      <div className="flex mb-4">
        {/* Swapped order: Components first, Templates second */}
        <button
          className={`mr-2 ${activeTab === 'components' ? 'font-bold' : 'text-gray-500'}`}
          onClick={() => setActiveTab('components')}
        >
          Components
        </button>

      </div>
      <div className="grid grid-cols-2 gap-4">
        {activeTab === 'components' ? (
          components.map((component) => (
            <TemplateCard
              key={component.id}
              item={component}
              onClick={() => handleItemClick(component.id, true)}
            />
          ))
        ) : (
          templates.map((template) => (
            <TemplateCard
              key={template.id}
              item={template}
              onClick={() => handleItemClick(template.id)}
            />
          ))
        )}
      </div>
    </div>
  );
}

