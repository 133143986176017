import React, { useEffect, useState } from 'react';
import { fabric } from 'fabric';

const Drawing = ({ canvas }) => {
  const [isDrawingMode, setIsDrawingMode] = useState(false);
  const [activeToolMode, setActiveToolMode] = useState('brush');
  const [brushColor, setBrushColor] = useState('#000000');
  const [brushWidth, setBrushWidth] = useState(5);

  useEffect(() => {
    if (canvas) {
      canvas.isDrawingMode = isDrawingMode;
      updateBrush();
    }
  }, [isDrawingMode, canvas, activeToolMode, brushColor, brushWidth]);

  const updateBrush = () => {
    if (!canvas) return;

    canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
    canvas.freeDrawingBrush.width = brushWidth;

    if (activeToolMode === 'eraser') {
      canvas.freeDrawingBrush.color = 'rgba(0,0,0,0)';
      canvas.freeDrawingBrush.globalCompositeOperation = 'destination-out';
    } else {
      canvas.freeDrawingBrush.color = brushColor;
      canvas.freeDrawingBrush.globalCompositeOperation = 'source-over';
    }

    canvas.on('path:created', function(e) {
      if (activeToolMode === 'eraser') {
        e.path.globalCompositeOperation = 'destination-out';
      }
    });
  };

  const toggleDrawingMode = () => {
    setIsDrawingMode(!isDrawingMode);
  };

  const handleColorChange = (color) => {
    setBrushColor(color);
    setActiveToolMode('brush');
  };

  const handleWidthChange = (width) => {
    setBrushWidth(parseInt(width, 10));
  };

  const enableEraser = () => {
    setActiveToolMode('eraser');
  };

  return (
    <div className="p-4 bg-gray-100 h-full">
      <h2 className="text-xl font-bold mb-4">Drawing Tools</h2>
      <button
        className={`mb-2 p-2 rounded ${isDrawingMode ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
        onClick={toggleDrawingMode}
      >
        {isDrawingMode ? 'Disable' : 'Enable'} Drawing Mode
      </button>
      <div className="mt-4">
        <label className="block mb-2">Brush Color</label>
        <input
          type="color"
          value={brushColor}
          onChange={(e) => handleColorChange(e.target.value)}
          className="w-full h-10 cursor-pointer"
        />
      </div>
      <div className="mt-4">
        <label className="block mb-2">Brush Width: {brushWidth}px</label>
        <input
          type="range"
          min="1"
          max="50"
          value={brushWidth}
          onChange={(e) => handleWidthChange(e.target.value)}
          className="w-full"
        />
      </div>

    </div>
  );
};

export default Drawing;
