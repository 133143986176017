import SideBar from "./SideBar";
import Scheduler from "./Scheduler"

const Calendar = () =>{

    return(
        <div className="flex ">
            <SideBar/>
            <div className="flex flex-col items-center justify-center bg-slate-100 w-[82%]" >
                <div className="h-20"></div>
                <Scheduler />
            </div>
        </div>
    )
}

export default Calendar;