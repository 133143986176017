import { fabric } from 'fabric';


let scrollLeft = 0;
let scrollTop = 0;
let pageHeight = 450;

export const setScrollPositionForMenu = (left, top) => {
  scrollLeft = left ;
  scrollTop = top;
  hideContextMenu()
};

export const getScrollPosition = () => ({
  left: scrollLeft,
  top: scrollTop,
});


export const addLink = (canvas, link)=>{
    const activeObject = canvas?.getActiveObject();
    if(activeObject){
        activeObject.link = link;
        canvas.renderAll();
    }
}

export const ObjectUtils = (canvas , isSideBarOpen) => {
    // Customize the object appearance
    fabric.Object.prototype.set({
        cornerSize: 7,
        cornerStyle: 'circle',
        cornerColor: '#98cfdf',
        borderColor: '#98cfdf',
        transparentCorners: false,
        cornerStrokeColor: '#98cfdf',
    });

    const deleteIcon = new Image();
    deleteIcon.src = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJ4AAACUCAMAAABVwGAvAAAAY1BMVEX///8AAAD4+PiwsLAZGRnW1tbn5+fHx8e8vLx0dHQrKysPDw9eXl7e3t6CgoLCwsKIiIhra2uVlZWjo6NmZmbPz896enpZWVny8vKdnZ1UVFQ1NTUwMDCOjo5HR0chISE8PDwIvZdTAAADPUlEQVR4nO3cfd9yMBQH8ItQYUUl81jv/1VecvVwprQNZ7q7z+9PTft+EGY5Pz/jYrPlPquqKnpKtdtvXT7y68eF7w+B9S55xWbEnd7a2gTxTMDkLMe1yebYxdX73SrsYs80jufKuEuWH62zHKM+W/WweyQxqDtq66zC3PG319dZVmhK5w3RWdbCEG/Arr2kNnP6c5+uFUWehuHhnrBJmtdOt1lmhLfr9LrZMo/bdvuZfQ3n3Eu6h2hhYvNxX+gzd/ubemvRtzXAS8QD6v35QjxMYwO8SOhRcjvCU2Hv2vi8WutoT+APxMG/dPAS9Oe/OfCuETYf/qlPOK3k8vYLyNuh85bwNq+StxcuMRE6bwW7W8nb27D9Bp0n7CyFuzjDPGHrqfwSMXicuW6yfJGkgt1lr5p0AtsfXjdxXaZ1N7gIS0d9pDM+gV9XyhdkHhuU3XOWn0P/ks2ha3yKvKcbNUNRu+SxmXSKJ+2V/ItwclDiDRqHTZFUiTfTL+NLeN2hDvHUeCxp4g4cZY9PnbTp5UXyrzCQXl4lX9dAiDcmxBuTXt5sZzwYh3gjcurlzXYzAON/Nq9/6y38c+E3OTmOMI4MHIx0O2g6Lsui6H+sxNlf3GZcDO+ZY3f6sKzbwbVztQH5FqyN8mgEPqw5aq8NeUeMh67EIx7xiEe8SXg2yM+I5dPx1uB7eRzek4Pldv5YnsIJhV36+ADOmEKe/lWzj+fBSSvIA4uF6Rg4pwvn1JB4cLaZeMQjHvGIRzziEY94xCMe8b6XF2Pz9P/Xh86DD6+JRzziEY94xCMe8YhHvH+WdyDed/HgG1XoPIU3tohHPOIRT86D/98j3ny8ELwgSDziEY94xBvIg29fDOCBv9yn2FtPv9pDgs2DW+8DebT1iKfAy4k3Fa+0gltEXvBYLvIey3F4sDyYzUDgCnA5LL/h9SyvUHiTZTreGYMX/S88lMqDm8l4JQLPno53wuCtJ+NZGLx4Op5qmR6NCJVLR/LUimloRaj6os9jBVxffzAgCZyXGFRZTqz2lHrdV5GGpn2nKRKqXJ0HHNvdikVlnV/eVmprY8a3rKW5N70V1UzzuluSdlBhudIylf4Kjb99xms5Y8JfYgAAAABJRU5ErkJggg==`;

    const duplicateIcon = new Image();
    duplicateIcon.src = `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_DdCuxH6G4Qcepm12uYcJhW0uSRzI14QA2A&s`;

    const rotationIcon = new Image();
    rotationIcon.src = `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4EQdbY3ow2l2RHEssXY5BWK5mk22T0p0Dfw&s`;

    const threeDotsIcon = new Image();
    threeDotsIcon.src = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAMAAACahl6sAAAAM1BMVEX///8AAACYmJhWVlbNzc0KCgqFhYXp6enKysoYGBj4+PjDw8PHx8cICAhZWVlUVFQWFhZ++obZAAABlUlEQVR4nO3bS5KEIBBFUdHyj1j7X20T3Q5axQFVA8gX96wgMwRFeDQNAAAAAAAAAAAAACDfsrbD0K5L6Tq+5LvN/eqDL13LF8bd/dONpev51DS7k/lVuqLPTL276E12Ms7XPuIzsTi69nsfcZ6UriqfT/Xh3FS6rmxdupFQuq5cy5ZupLf2ZVzTfTi3lq4sU/vUSFu6skzDUyND6coyyTQiM7RkJvtyW2j9Mff6bUK6EXMfRJ0lSnqNYu+BCC3jm5fIj1XsRORXN46u0zwJJsfVYQrH+OqDwffVicgGHQAAAAAAAGCTyAYdCbqqkKCri8zRGwm6ypCgq4xMqEYm5iQTPJNpRGZoyUx2EnTVkVmikKCrDwm6+pCgq5HIBh0AAAAAAABgk8gG3bofCYit00nQ7WY3sf37cqxgdB/b3w96THYyvq99GD16Sybo9tJV5XuIPth7dyUfiMHzaRJ0tZGJOckEz2QakRlaMpNd5vUrEykXStCpXLsQStCpXE3SuSymc30v8iToAAAAAAAAAAAAAADRDxsWDf2ziY1JAAAAAElFTkSuQmCC`;

    fabric.Object.prototype.controls.deleteControl = new fabric.Control({
        x: 0.5,
        y: -0.5,
        offsetX: -30,
        offsetY: -50,
        cursorStyle: 'pointer',
        mouseUpHandler: function(eventData, transform) {
            const target = transform.target;
            canvas.remove(target);
            canvas.requestRenderAll();
        },
        render: function(ctx, left, top, styleOverride, fabricObject) {
            const size = this.cornerSize;
            ctx.save();
            ctx.translate(left, top);
            ctx.drawImage(deleteIcon, -size / 2, -size / 2, size, size);
            ctx.restore();
        },
        cornerSize: 24,
    });

    fabric.Object.prototype.controls.duplicateControl = new fabric.Control({
        x: 0.5,
        y: -0.5,
        offsetX: -70,
        offsetY: -50,
        cursorStyle: 'pointer',
        mouseUpHandler: function(eventData, transform) {
            const target = transform.target;
            const canvas = target.canvas; // Access the canvas from the target object
    
            // Use the clone method provided by Fabric.js
            target.clone(function(clone) {
                clone.set({
                    left: target.left + 10, // Offset to avoid overlap
                    top: target.top + 10,
                    evented: true,
                });
                
                // Add the cloned object to the canvas
                canvas.add(clone);
                canvas.setActiveObject(clone);
                canvas.requestRenderAll();
            });
    
            return true;
        },
        render: function(ctx, left, top, styleOverride, fabricObject) {
            const size = this.cornerSize;
            ctx.save();
            ctx.translate(left, top);
            ctx.drawImage(duplicateIcon, -size / 2, -size / 2, size, size);
            ctx.restore();
        },
        cornerSize: 24,
    });
    

    fabric.Object.prototype.controls.mtr = new fabric.Control({
        x: 0,
        y: 0.5,
        offsetY: 35,
        cursorStyle: 'crosshair',
        actionHandler: fabric.controlsUtils.rotationWithSnapping,
        actionName: 'rotate',
        render: function(ctx, left, top, styleOverride, fabricObject) {
            const size = 30;
            ctx.save();
            ctx.translate(left, top);
            ctx.drawImage(rotationIcon, -size / 2, -size / 2, size, size);
            ctx.restore();
        },
        cornerSize: 24,
    });

    fabric.Object.prototype.controls.menuControl = new fabric.Control({
        x: 0.5,
        y: -0.5,
        offsetX: 0,
        offsetY: -50,
        cursorStyle: 'pointer',
        mouseUpHandler: function(eventData, transform) {
            const target = transform.target;
            showMenu(target);
        },
        render: function(ctx, left, top, styleOverride, fabricObject) {
            const size = this.cornerSize;
            ctx.save();
            ctx.translate(left, top);
            ctx.drawImage(threeDotsIcon, -size / 2, -size / 2, size, size);
            ctx.restore();
        },
        cornerSize: 24,
    });

    function showMenu(target) {
        const existingMenu = document.getElementById('fabric-object-menu');
        if (existingMenu) {
            existingMenu.remove();
        }
    
        const menu = document.createElement('div');
        menu.id = 'fabric-object-menu';
        menu.style.position = 'absolute';
        menu.style.backgroundColor = 'white';
        menu.style.border = '1px solid #ccc';
        menu.style.borderRadius = '4px';
        menu.style.padding = '10px';
        menu.style.zIndex = '1000';
        menu.style.boxShadow = '0 2px 10px rgba(0,0,0,0.1)';
        menu.style.minWidth = '200px';
    
        const createMenuItem = (text, onClick) => {
            const item = document.createElement('div');
            item.textContent = text;
            item.style.cursor = 'pointer';
            item.style.padding = '5px 10px';
            item.style.transition = 'background-color 0.2s';
            item.onmouseover = () => item.style.backgroundColor = '#f0f0f0';
            item.onmouseout = () => item.style.backgroundColor = 'transparent';
            item.onclick = onClick;
            return item;
        };
    
        const bringToFront = createMenuItem('Bring to Front', () => {
            target.bringToFront();
            canvas.requestRenderAll();
            menu.remove();
        });
    
        const sendToBack = createMenuItem('Send to Back', () => {
            target.sendToBack();
            canvas.requestRenderAll();
            menu.remove();
        });
    
        const linkInput = document.createElement('input');
        linkInput.type = 'text';
        linkInput.placeholder = 'Enter link URL';
        linkInput.style.width = '100%';
        linkInput.style.padding = '5px';
        linkInput.style.marginTop = '10px';
        linkInput.style.border = '1px solid #ccc';
        linkInput.style.borderRadius = '3px';
        linkInput.value = target.Link || '';
    
        const addLinkButton = createMenuItem('Add/Update Link', () => {
            const link = linkInput.value.trim();
            addLink(canvas, link);
            menu.remove();
        });
    
        menu.appendChild(bringToFront);
        menu.appendChild(sendToBack);
        menu.appendChild(linkInput);
        menu.appendChild(addLinkButton);
    
        // const pointer = target.oCoords.tr;
        // const boundingBox = target.getBoundingRect(true);
    
        function calculateAdjustedY(y, pageHeight) {
            // const pageOffset = Math.floor(y / pageHeight) * 400;
            return y + 80 - scrollTop;
        }
    
        // const sidebarOffset = isSideBarOpen === "closed" ? 120 : 1200;
    
        menu.style.left = `${target.oCoords.tr.x + 140}px`;
        menu.style.top = `${calculateAdjustedY(target.oCoords.tr.y, pageHeight)}px`;
    
        document.body.appendChild(menu);
    
        const closeMenu = function(e) {
            if (!menu.contains(e.target)) {
                menu.remove();
                document.removeEventListener('click', closeMenu);
            }
        };
    
        requestAnimationFrame(() => {
            document.addEventListener('click', closeMenu);
        });
    }
};

export const hideContextMenu = () => {
    const menu = document.getElementById('fabric-object-menu'); 
    if (menu) {
      menu.style.display = 'none'; 
    }
  };
  
