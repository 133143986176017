import React, { useState, useEffect } from "react";

const Color_Picker = ({ canvas }) => {
  const [color, setColor] = useState("#121212");

  useEffect(() => {
    
    if (canvas) {
      canvas.on("selection:created", fetchInitialColor);
      canvas.on("selection:updated", fetchInitialColor);
    }
    return () => {
      if (canvas) {
        canvas.off("selection:created", fetchInitialColor);
        canvas.off("selection:updated", fetchInitialColor);
      }
    };
  }, [canvas]);

  const changeActiveObjectColor = (newColor) => {
    try{
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      activeObject.set("fill", newColor);
      canvas.renderAll();
      canvas.fire('object:modified');
    }
  }catch(e){

  }
  };

  const fetchInitialColor = () => {
    try{
    if (canvas) {
      const activeObject = canvas.getActiveObject();
      if (activeObject ) {
        let initialColor;
        if (typeof activeObject.fill === 'string') {
          initialColor = activeObject.fill;
        } else if (activeObject.fill && Array.isArray(activeObject.fill.colorStops)) {
          initialColor = activeObject.fill.colorStops[0].color;
        } else if (activeObject.color) {
          initialColor = activeObject.color;
        } else {
          initialColor = "#121212";
        }
        setColor(initialColor);
      }
    }
  }catch(e){}
  };

  const handleColorChange = (e) => {
    const newColor = e.target.value;
    setColor(newColor);
    changeActiveObjectColor(newColor);
  };

  return (
    <div className="mt-5">
      <div className="text-black ml-2 flex items-center">
        <label className="mr-16">Color</label>
        <div className="flex bg-slate-50 rounded-xl w-40 items-center">
          <input
            type="color"
            value={color}
            onChange={handleColorChange}
            className="h-7 w-12 rounded-full mr-3 ml-1"
          />
          <p>{color}</p>
        </div>
      </div>
    </div>
  );
};

export default Color_Picker;


 
 

