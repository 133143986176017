import React, { useEffect,useState } from 'react';
import {data} from "../app/DummyData"
import {convertCanvasToHTML , downloadHTML} from "./utils/toHtml"

 const Nav =({canvas, postId})=>{
    const [post, setPost] = useState(null);
    const[image, setImage] = useState(null);
    const [exportOpen, setExportOpen] = useState(false);
    

    useEffect(() => {
      const foundPost = data.find((post) => post.postId === postId);
      if (foundPost) {
        setPost(foundPost);
      }
    }, [postId]);

    const saveCanvasAsJson = () => {
      try{
      post.designData = JSON.stringify(canvas.toJSON())
      setImage(canvas.toDataURL({format:'png', quality:1.0}))
      post.media[0].url = image
      }catch(e){
        console.log(e)
      }

    }

    const saveCanvasAsImage = () => {

        const dataURL = canvas.toDataURL({
          format: 'png',
          quality: 1.0
        });
        const link = document.createElement('a');
        link.href = dataURL;
        link.download = 'canvas-image.png';
        link.click();
      };

      const generateAndDownloadHTML = () => {
        if (canvas) {
          const html = convertCanvasToHTML(canvas);
          downloadHTML(html);
        }
      };
      
      const openHTMLInNewWindow = () => {
        if (canvas) {
          const htmlContent = convertCanvasToHTML(canvas);
          const newWindow = window.open('', '_blank');
          newWindow.document.write(`
            <!DOCTYPE html>
            <html lang="en">
            <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>Canvas HTML Preview</title>
              <style>
                body, html {
                  margin: 0;
                  padding: 0;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: #f0f0f0;
                }
              </style>
            </head>
            <body>
              ${htmlContent}
            </body>
            </html>
          `);
          newWindow.document.close();
        }
      };
      
      const saveCanvasAsHTML = () => {
        if (canvas) {
          const htmlContent = convertCanvasToHTML(canvas);
          const fullHTMLContent = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Canvas HTML Export</title>
        <style>
          body, html {
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #f0f0f0;
          }
        </style>
      </head>
      <body>
        ${htmlContent}
      </body>
      </html>`;
      
          // Create a Blob with the HTML content
          const blob = new Blob([fullHTMLContent], { type: 'text/html' });
      
          // Create a temporary URL for the Blob
          const url = URL.createObjectURL(blob);
      
          // Create a link element and trigger the download
          const link = document.createElement('a');
          link.href = url;
          link.download = 'canvas_export.html';
          document.body.appendChild(link);
          link.click();
      
          // Clean up
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        }
      };
      return (
        <div className="flex h-14 bg-slate-200">
            <div className="flex justify-evenly w-3/4 text-slate-200" onClick={openHTMLInNewWindow}>
                Website
            </div>
            <div className="flex justify-evenly items-center w-1/2">
                <button className="ml-[350px]" onClick={openHTMLInNewWindow}>Preview</button>
                <button onClick={saveCanvasAsJson}>Save</button>
                <div className="relative inline-block">
                    <button onClick={() => setExportOpen(!exportOpen)}>Export</button>
                    {exportOpen && (
                        <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                <button
                                    onClick={saveCanvasAsImage}
                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                                    role="menuitem"
                                >
                                    Export as Image
                                </button>
                                <button
                                    onClick={saveCanvasAsHTML}
                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                                    role="menuitem"
                                >
                                    Export as HTML
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

}

export default Nav;