import React, { useState } from "react";
import { data } from "./DummyData";
import { useNavigate } from "react-router-dom";
// import PlatformToggle from "./PlatformToggle";

const ContentForm = ({ onClose }) => {
  const [contentType, setContentType] = useState("post");
  const [formData, setFormData] = useState({
    postId: "",
    userId: "",
    type: "",
    content: "",
    subject: "",
    media: [{ type: "image", url: "" }],
    designData: "{\"objects\":[],\"background\":\"\"}",
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    status: "draft",
    platforms: [],
    tags: [],
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleMediaChange = (e) => {
    setFormData({
      ...formData,
      media: [{ type: "image", url: e.target.value }],
    });
  };

  const handlePlatformToggle = (platform) => {
    setFormData((prevFormData) => {
      const platforms = prevFormData.platforms.includes(platform)
        ? prevFormData.platforms.filter((p) => p !== platform)
        : [...prevFormData.platforms, platform];
      return { ...prevFormData, platforms };
    });
  };

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const newPost = { ...formData, postId: `post${data.length + 1}` };
    data.push(newPost);
    navigate(`/view/${newPost.postId}`);
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded shadow-md w-[40%] ">
        <h2 className="text-xl mb-4">Create New {contentType === "post" ? "Post" : "Email"}</h2>
        <div className="flex mb-4">
          <button
            className={`px-4 py-2 mr-2 rounded ${contentType === "post" ? "bg-blue-500 text-white" : "bg-gray-300"}`}
            onClick={() => setContentType("post")}
          >
            Post
          </button>
          <button
            className={`px-4 py-2 rounded ${contentType === "email" ? "bg-blue-500 text-white" : "bg-gray-300"}`}
            onClick={() => setContentType("email")}
          >
            Email
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          
          <div className="flex justify-end">
            <button
              type="button"
              className="mr-4 bg-gray-300 px-4 py-2 rounded"
              onClick={onClose}
            >
              Cancel
            </button>
            <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContentForm;
