import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fabric } from 'fabric';
import SideBar from './sidebar';
import UtilBar from './utilBar';
import CustomizeControls from './utils/CustomizeControls';
// import CanvasColor from './utils/CanvasBackgroundColor';
import Nav from './nav';
import { smartGuide } from './utils/smartGuide';
import { ObjectUtils, setScrollPositionForMenu } from './utils/objectUtils';
import { mockTemplates } from "./utils/templateUtils"
import { setScrollPosition } from './utils/shapesUtils'; 
import { setScrollPositionForComponents } from './utils/templateUtils';
import { increaseCanvasHeight, adjustCanvasHeight ,hideContextMenu, setScrollPositionForActiveSection } from './pageManager';

const EditorConsole = () => {
  const canvasRef = useRef(null);
  const scrollableDivRef = useRef(null);
  const canvasInstance = useRef(null); // Use useRef to keep a stable canvas instance
  const [isSideBarOpen, setIsSideBarOpen] = useState('closed');
  const { Id } = useParams();
  const [template, setTemplate] = useState(null);

  useEffect(() => {
    const template = mockTemplates.find(template => template.id === Number(Id));
    if (template) {
      
      setTemplate(template);
    }
  }, [Id]);

  useEffect(() => {
    if (!canvasInstance.current) {
      const canvas = new fabric.Canvas(canvasRef.current, {
        width: 900,
        height: 450,
        backgroundColor: '#ffffff',
        preserveObjectStacking: true,
      });
      
      ObjectUtils(canvas, isSideBarOpen);
      smartGuide(canvas);
      canvasInstance.current = canvas;

      if (template) {
        canvas.loadFromJSON(template.canvasJson, () => {
          canvas.renderAll();
        });
      }
    } else {
      const canvas = canvasInstance.current;
      if (template) {
        canvas.loadFromJSON(template.canvasJson, () => {
          adjustCanvasHeight(canvas);
          canvas.renderAll();
        });
      }
    }

    const handleScroll = () => {
      const scrollTop = scrollableDivRef.current.scrollTop;
      const scrollLeft = scrollableDivRef.current.scrollLeft;
      setScrollPosition(scrollLeft, scrollTop);
      setScrollPositionForMenu(scrollLeft, scrollTop);
      setScrollPositionForComponents(scrollLeft, scrollTop);
      setScrollPositionForActiveSection(scrollLeft, scrollTop, canvasInstance.current);
    };

    const handleClickOutside = (event) => {
      const menu = document.getElementById('fabric-object-menu'); 
      if (menu && !menu.contains(event.target)) {
        hideContextMenu(); 
      }
    };

    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        hideContextMenu(); 
      }
    };

    if (scrollableDivRef.current) {
      scrollableDivRef.current.addEventListener('scroll', handleScroll);
    }
    document.addEventListener('click', handleClickOutside); 
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      if (scrollableDivRef.current) {
        scrollableDivRef.current.removeEventListener('scroll', handleScroll);
      }
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [template]);
// }, [template, isSideBarOpen]); isSideBarOpen was used here, I dont remember why but it has now been removed

  const toggleSideBar = (state) => {
    setIsSideBarOpen(state);
  };

  return (
    <div>
      <Nav canvas={canvasInstance.current} postId={Id} />
      <div className="flex bg-[#f8f8f8] w-full h-[91vh] bg-[linear-gradient(to_right,#d1d1d1_1px,transparent_1px),linear-gradient(to_bottom,#d1d1d1_1px,transparent_1px)] bg-[size:24px_24px]">
        <SideBar canvas={canvasInstance.current} toggleSideBar={toggleSideBar} />
        <div ref={scrollableDivRef} className="flex flex-1 justify-center mt-[30px] overflow-auto max-h-[85vh]">
          <div className="relative">
            
            <div id="canvas-container" >
              <canvas ref={canvasRef} />
            </div>
            <CustomizeControls canvas={canvasInstance.current} />
            <button onClick={() => increaseCanvasHeight(canvasInstance.current)} className="btn-increase-width">Add Page</button>
          </div>
        </div>
        <div>
          {isSideBarOpen === 'closed' && <UtilBar canvas={canvasInstance.current} />}
        </div>
      </div>
    </div>
  );
};

export default EditorConsole;
