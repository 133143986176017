import React, { useState } from "react";
import SideBar from "./SideBar";

const AutomaticCampaignContent= ()=>{

    return(
        <div className="flex w-full">
            <SideBar/>
            <div className="flex flex-col w-full items-center bg-slate-100">auto maticccc</div>
        </div>
    )
}

export default AutomaticCampaignContent;