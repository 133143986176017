import React from 'react';

const ImageSelector = ({ images, onSelect, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg max-w-3xl max-h-[80vh] overflow-y-auto">
        <h3 className="text-xl font-bold mb-4">Select an Image</h3>
        <div className="grid grid-cols-3 gap-4">
          {images.map(image => (
            <div key={image.id} className="cursor-pointer hover:opacity-80" onClick={() => onSelect(image.url)}>
              <img src={image.url} alt={image.name} className="w-full h-32 object-cover rounded" />
              <p className="mt-1 text-sm text-gray-600">{image.name}</p>
            </div>
          ))}
        </div>
        <button 
          onClick={onClose}
          className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ImageSelector;