import { Link } from "react-router-dom";

const SideBar = () => {
  return (
    <div className="h-screen">
      <div className="">LOGO</div>
      <div className="bg-white w-60  h-[96%] flex flex-col justify-evenly">
        <Link to="/">Dashboard</Link>
        <Link to="/library">Library</Link>
        <Link to="/template-load">Website Studio</Link>
        <Link to="/groups/contact-management">Groups</Link>
        <Link to="/calendar">Calendar</Link>
        <Link to="/notes">Notes</Link>
        <Link to="/settings">Settings</Link>
      </div>
    </div>
  );
};

export default SideBar;
