import {addBodyText, addHeadingText, addSubHeadingText} from "../utils/TextUtils"
import {deleteElement} from "../utils/DeleteElementUtils"

export default function Text({ canvas }){
    return(
        <div>
            <div className="toolbar bg-slate-50 h-screen flex flex-col items-center">
                <br/>
                <button  onClick={() => addHeadingText(canvas)} className ="w-80 h-10 text-2xl border-2 rounded-2xl border-slate-700">Add Heading Text</button>
                <br/>
                <button  onClick={() => addSubHeadingText(canvas)} className ="w-80 h-10 text-lg border-2 rounded-2xl border-slate-700">Sub Heading</button>
                <br/>
                <button  onClick={() => addBodyText(canvas)} className ="w-80 h-10 text-base border-2 rounded-2xl border-slate-700">Body Text</button>
                
            </div>
        </div>
    );
}