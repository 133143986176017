import React from 'react';
import { Link } from "react-router-dom";
import SideBar from "./SideBar";
import { mockTemplates } from "../editor/utils/templateUtils";

const TemplateCard = ({ template }) => (
  <div className="bg-white rounded-lg overflow-hidden shadow-md transition-all duration-300 hover:shadow-lg hover:-translate-y-1">
    <Link to={`/editor/${template.id}`} className="block h-full">
      <img
        src={template.thumbnail}
        alt={template.name}
        className="w-full h-40 object-cover transition-transform duration-300 hover:scale-105"
      />
      <div className="p-4">
        <h3 className="font-bold text-lg mb-2 text-gray-800">{template.name}</h3>
        <p className="text-sm text-gray-600 line-clamp-2">{template.description}</p>
      </div>
      <div className="px-4 py-3 bg-gray-50">
        <span className="text-xs text-gray-500">Click to edit</span>
      </div>
    </Link>
  </div>
);

const TemplateLoad = () => {
  return (
    <div className="flex h-screen">
      <SideBar />
      <div className="flex-1 overflow-auto bg-slate-100">
        <div className="p-6">
          <h2 className="text-2xl font-bold mb-6">Choose a Template</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {mockTemplates.map((template) => (
              <TemplateCard key={template.id} template={template} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateLoad;