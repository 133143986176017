import React, { useState, useEffect } from 'react';
//import { fabric } from 'fabric';

const BorderColor = ({ canvas }) => {
  const [borderColor, setBorderColor] = useState('#000000'); // Initial border color, default black
  const [borderSize, setBorderSize] = useState(1); // Initial border size

  useEffect(() => {
    const fetchInitBorderColor = () => {
      const activeObject = canvas?.getActiveObject();
      if (activeObject) {
        setBorderColor(activeObject.stroke || '#000000'); // Set initial border color from active object stroke color
        setBorderSize(activeObject.strokeWidth || 0);// Set initial border size from active object stroke width
      }
    };

    if (canvas) {
      canvas.on("selection:created", fetchInitBorderColor);
      canvas.on("selection:updated", fetchInitBorderColor);
    }

    return () => {
      if (canvas) {
        canvas.off("selection:created", fetchInitBorderColor);
        canvas.off("selection:updated", fetchInitBorderColor);
      }
    };
  }, [canvas]);

  const adjustBoundingBox = (object, borderWidth) => {
    const scaleFactor = borderWidth * 2; // Expand by border width on both sides
    const newWidth = object.width + scaleFactor;
    const newHeight = object.height + scaleFactor;

    // Update the object's size and position
    object.set({
      width: newWidth,
      height: newHeight,
      left: object.left - borderWidth,
      top: object.top - borderWidth,
    });
  };

  const handleBorderColorChange = (color) => {
    setBorderColor(color);
    const activeObject = canvas?.getActiveObject();
    if (activeObject) {
      activeObject.set('stroke', color);
      canvas.renderAll();
    }
  };

  const handleBorderSizeChange = (size) => {
    setBorderSize(size);
    const activeObject = canvas?.getActiveObject();
    if (activeObject) {

      if(size>0){
        adjustBoundingBox(activeObject, size - borderSize);
      }
      activeObject.set('strokeWidth', size);
      canvas.renderAll();
    }
  };

  const handleRangeChange = (e) => {
    const newSize = parseInt(e.target.value, 10);
    setBorderSize(newSize);
    handleBorderSizeChange(newSize);
  };

  return (
    <div className="flex text-black ml-2 mt-5">

      <label className="mr-14">Border</label>
      <div className="flex bg-slate-50 rounded-xl w-40 " >
        <input
          type="color"
          value={borderColor}
          onChange={(e) => handleBorderColorChange(e.target.value)}
          className="h-7 w-12 rounded-full mr-8 ml-1"/>

        <input
          type="number"
          value={borderSize}
          onChange={handleRangeChange}
          min="0"
          className="w-14 h-7 text-center border-2 border-slate-300 rounded-xl "
        />
        <span>px</span>
      </div>
    </div>
  );
};

export default BorderColor;

