import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Popup = ({ content, onClose }) => {
  const majorPlatforms = ["Facebook", "Twitter", "Instagram", "LinkedIn"];
  const [newPlatform, setNewPlatform] = useState("");
  const [platforms, setPlatforms] = useState(content.platforms);
  const [status, setStatus] = useState(content.status);
  const [scheduleDate, setScheduleDate] = useState(null);
  const [textAreaValue, setTextAreaValue] = useState(content.content);

  const handleAddPlatform = () => {
    if (newPlatform && !platforms.includes(newPlatform)) {
      setPlatforms([...platforms, newPlatform]);
      setNewPlatform("");
    }
  };

  const handleRemovePlatform = (platformToRemove) => {
    setPlatforms(platforms.filter(platform => platform !== platformToRemove));
  };

  const handleTextareaChange = (e) => {
    setTextAreaValue(e.target.value);
  };

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white w-11/12 md:w-3/4 lg:w-3/4 p-6 rounded shadow-lg relative">
        <button
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          &times;
        </button>
        <div className="flex">
          <div className="mr-4 w-[50%]">
            {content.media.length > 0 &&
              content.media.map((media, index) => (
                <div key={index} className="mb-4">
                  {media.type === "image" && (
                    <img src={media.url} alt={`Media ${index + 1}`} className="w-full rounded mb-4" />
                  )}
                  {media.type === "video" && (
                    <video controls className="w-full rounded mb-4 h-60">
                      <source src={media.url} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </div>
              ))}

            {!content.subject && (
              <div className="mb-4">
                <h3 className="text-lg font-semibold mb-2">Platforms:</h3>
                <ul className="flex list-disc list-inside">
                  {platforms.map((platform, index) => (
                    <li className="mr-5 flex items-center" key={index}>
                      {platform}
                      <button
                        className="ml-2 text-red-500 hover:text-red-700"
                        onClick={() => handleRemovePlatform(platform)}
                      >
                        &times;
                      </button>
                    </li>
                  ))}
                </ul>
                <div className="flex items-center mt-2">
                  <select
                    value={newPlatform}
                    onChange={(e) => setNewPlatform(e.target.value)}
                    className="border rounded px-2 py-1 mr-2"
                  >
                    <option value="">Select platform</option>
                    {majorPlatforms.map(platform => (
                      <option key={platform} value={platform}>
                        {platform}
                      </option>
                    ))}
                  </select>
                  <button
                    className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-700"
                    onClick={handleAddPlatform}
                  >
                    Add
                  </button>
                </div>
              </div>
            )}
            <Link to={`/editor/${content.postId}`} className="text-blue-500">edit</Link>
          </div>

          <div className="w-[50%]">
            <div className="mb-4">
              <p className="text-gray-600">Created At: {new Date(content.createdAt).toLocaleString()}</p>
              <p className="text-gray-600">Updated At: {new Date(content.updatedAt).toLocaleString()}</p>
            </div>
            <div className="mb-4">
              <textarea
                className="w-full h-52 overflow-y-auto border rounded p-2"
                value={textAreaValue}
                onChange={handleTextareaChange}
                {...(!content.subject && { maxLength: 280 })}
              ></textarea>
              {!content.subject && (
                <p className="text-right text-gray-600">{textAreaValue.length}/280</p>
              )}
            </div>
            <div className="mb-4">
              <label htmlFor="status" className="block mb-2">Status:</label>
              <select 
                id="status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className="border rounded px-2 py-1 w-full"
              >
                <option value="draft">Draft</option>
                <option value="scheduled">Scheduled</option>
                <option value="posted" disabled>Posted</option>
              </select>
            </div>
            {status === 'scheduled' && (
              <div className="mb-4">
                <label htmlFor="scheduleDate" className="block mb-2">Schedule Date:</label>
                <DatePicker
                  id="scheduleDate"
                  selected={scheduleDate}
                  onChange={(date) => setScheduleDate(date)}
                  showTimeSelect
                  dateFormat="Pp"
                  className="border rounded px-2 py-1 w-full"
                />
              </div>
            )}
            {status === 'posted' && (
              <div className="mb-4">
                <p>Post Date: {new Date(content.postedAt).toLocaleString()}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
