import { fabric } from 'fabric';
// globalState.js



export const mockTemplates = [
  {
    id: 1,
    name: 'Blank',
    thumbnail: '/images/home-template-thumbnail-updated.jpg',
    description: 'Start designing your way on a blank canvas',
    canvasJson: {
      "version": "5.2.1",
      "objects": [],
      "background": "#ffffff"
    }
  }
,
{
  id: 6,
  name: 'Shop',
  thumbnail: '/images/home-template-thumbnail-updated.jpg',
  description: 'A vibrant, modern e commerce layout with a gradient background and stylized text',
  canvasJson: {
    "version": "5.2.1",
    "objects": [
      {
        "type": "rect",
        "version": "5.2.1",
        "originX": "left",
        "originY": "top",
        "left": 0,
        "top": 0,
        "width": 900,
        "height": 600,
        "fill": "#feb47b", // Gradient background
        "stroke": null,
        "strokeWidth": 0,
        "shadow": {
          "color": "rgba(0, 0, 0, 0.3)",
          "blur": 10,
          "offsetX": 5,
          "offsetY": 5
        },
        "visible": true,
      },
      {
        "type": "textbox",
        "version": "5.2.1",
        "originX": "left",
        "originY": "top",
        "left": 100, // Positioned well within the canvas width
        "top": 50,
        "width": 700, // Text width reduced for margins
        "height": 100,
        "fill": "#ffffff", // White text for contrast
        "text": "Welcome to Our Vibrant Website",
        "fontSize": 48, // Larger, modern font size
        "fontWeight": "bold", // Bold for emphasis
        "fontFamily": "Helvetica, Arial, sans-serif", // Clean, modern font
        "textAlign": "center",
        "shadow": {
          "color": "rgba(0, 0, 0, 0.2)",
          "blur": 10,
          "offsetX": 3,
          "offsetY": 3
        },
        "visible": true,
        "styles": {}
      },
      {
        "type": "textbox",
        "version": "5.2.1",
        "originX": "left",
        "originY": "top",
        "left": 100, // Well-spaced margin
        "top": 150,
        "width": 700,
        "height": 50,
        "fill": "#ffffff", // White text for contrast
        "text": "Discover modern designs that captivate.",
        "fontSize": 24, // Subheading text
        "fontWeight": "normal",
        "fontFamily": "Helvetica, Arial, sans-serif",
        "textAlign": "center",
        "visible": true,
        "styles": {}
      },
      {
        "type": "circle", // A circle element to add style
        "version": "5.2.1",
        "originX": "left",
        "originY": "top",
        "left": 750,
        "top": 500,
        "radius": 30,
        "fill": "#ffffff", // Contrasting color
        "stroke": "#ff7e5f",
        "strokeWidth": 4,
        "visible": true,
        "styles": {}
      }
    ],
    "background": "#ffffff" // Ensures clean look if no gradient fills
  }
}
,
   {
    id: 2,
    name: 'Portfolio',
    thumbnail: '/images/portfolio-template-thumbnail.jpg',
    description: 'A modern portfolio page layout with image placeholders and styled text',
    canvasJson: {
      "version": "5.2.1",
      "objects": [
        {
          "type": "rect",
          "version": "5.2.1",
          "originX": "left",
          "originY": "top",
          "left": 0,
          "top": 0,
          "width": 900,
          "height": 600,
          "fill": "#f4f4f9", // Light gray background
          "visible": true,
          "styles": {}
        },
        {
          "type": "textbox",
          "version": "5.2.1",
          "originX": "left",
          "originY": "top",
          "left": 50,
          "top": 30,
          "width": 800,
          "height": 80,
          "fill": "#333333", // Dark gray text
          "text": "Welcome to My Portfolio",
          "fontSize": 42,
          "fontWeight": "bold",
          "fontFamily": "Georgia, serif",
          "textAlign": "center",
          "visible": true,
          "styles": {}
        },
        {
          "type": "image",
          "version": "5.2.1",
          "left": 50,
          "top": 120,
          "width": 250,
          "height": 250,
          "fill": "transparent",
          "src": "/images/portfolio-image-placeholder.jpg", // Image placeholder
          "visible": true,
          "styles": {}
        },
        {
          "type": "textbox",
          "version": "5.2.1",
          "originX": "left",
          "originY": "top",
          "left": 350,
          "top": 120,
          "width": 500,
          "height": 150,
          "fill": "#333333",
          "text": "Project Name\nA brief description of the project goes here.",
          "fontSize": 24,
          "fontWeight": "normal",
          "fontFamily": "Arial, sans-serif",
          "textAlign": "left",
          "visible": true,
          "styles": {}
        },
        {
          "type": "rect", // Separator line
          "version": "5.2.1",
          "originX": "left",
          "originY": "top",
          "left": 50,
          "top": 400,
          "width": 800,
          "height": 2,
          "fill": "#333333",
          "visible": true,
          "styles": {}
        }
      ],
      "background": "#ffffff" // Clean background
    }
  }
  ,
   {
      id: 3,
      name: 'Landing Page',
      thumbnail: '/images/landing-page-template-thumbnail.jpg',
      description: 'A sleek landing page layout with a call-to-action and vibrant colors',
      canvasJson: {
      "version": "5.2.1",
      "objects": [
          {
          "type": "rect",
          "version": "5.2.1",
          "originX": "left",
          "originY": "top",
          "left": 0,
          "top": 0,
          "width": 900,
          "height": 600,
          "fill": "#ec2F4B", // Vibrant gradient background
          "visible": true,
          "styles": {}
          },
          {
          "type": "textbox",
          "version": "5.2.1",
          "originX": "left",
          "originY": "top",
          "left": 50,
          "top": 50,
          "width": 800,
          "height": 100,
          "fill": "#ffffff", // White text for contrast
          "text": "Build Your Dream Website",
          "fontSize": 48,
          "fontWeight": "bold",
          "fontFamily": "Roboto, sans-serif",
          "textAlign": "center",
          "shadow": {
              "color": "rgba(0, 0, 0, 0.2)",
              "blur": 10,
              "offsetX": 2,
              "offsetY": 2
          },
          "visible": true,
          "styles": {}
          },
          {
          "type": "textbox",
          "version": "5.2.1",
          "originX": "left",
          "originY": "top",
          "left": 100,
          "top": 200,
          "width": 700,
          "height": 50,
          "fill": "#ffffff",
          "text": "We create modern, responsive websites that drive results.",
          "fontSize": 24,
          "fontWeight": "normal",
          "fontFamily": "Roboto, sans-serif",
          "textAlign": "center",
          "visible": true,
          "styles": {}
          },
          {
          "type": "rect", // Call-to-action button
          "version": "5.2.1",
          "originX": "left",
          "originY": "top",
          "left": 350,
          "top": 350,
          "width": 200,
          "height": 50,
          "fill": "#ffffff",
          "stroke": "#ec2F4B",
          "strokeWidth": 3,
          "visible": true,
          "styles": {}
          },
          {
          "type": "textbox",
          "version": "5.2.1",
          "originX": "left",
          "originY": "top",
          "left": 360,
          "top": 360,
          "width": 180,
          "height": 40,
          "fill": "#ec2F4B",
          "text": "Get Started",
          "fontSize": 20,
          "fontWeight": "bold",
          "fontFamily": "Arial, sans-serif",
          "textAlign": "center",
          "visible": true,
          "styles": {}
          }
      ],
      "background": "#ffffff"
      }
  }
  ,
   {
      id: 4,
      name: 'Business',
      thumbnail: '/images/business-template-thumbnail.jpg',
      description: 'A professional business page layout with a clean structure and a focus on content',
      canvasJson: {
      "version": "5.2.1",
      "objects": [
          {
          "type": "rect",
          "version": "5.2.1",
          "originX": "left",
          "originY": "top",
          "left": 0,
          "top": 0,
          "width": 900,
          "height": 600,
          "fill": "#e0e0e0", // Light gray background
          "visible": true,
          "styles": {}
          },
          {
          "type": "textbox",
          "version": "5.2.1",
          "originX": "left",
          "originY": "top",
          "left": 50,
          "top": 50,
          "width": 800,
          "height": 100,
          "fill": "#000000", // Black text for professionalism
          "text": "Professional Business Solutions",
          "fontSize": 36,
          "fontWeight": "bold",
          "fontFamily": "Times New Roman, serif",
          "textAlign": "center",
          "visible": true,
          "styles": {}
          },
          {
          "type": "textbox",
          "version": "5.2.1",
          "originX": "left",
          "originY": "top",
          "left": 100,
          "top": 200,
          "width": 700,
          "height": 150,
          "fill": "#333333", // Darker text for content
          "text": "Our team offers customized solutions tailored to your business needs, providing quality services and unmatched professionalism.",
          "fontSize": 20,
          "fontWeight": "normal",
          "fontFamily": "Georgia, serif",
          "textAlign": "left",
          "visible": true,
          "styles": {}
          },
          {
          "type": "rect", // Content box
          "version": "5.2.1",
          "originX": "left",
          "originY": "top",
          "left": 50,
          "top": 400,
          "width": 800,
          "height": 150,
          "fill": "#ffffff", // White box for content
          "visible": true,
          "styles": {}
          },
          {
          "type": "textbox",
          "version": "5.2.1",
          "originX": "left",
          "originY": "top",
          "left": 60,
          "top": 410,
          "width": 780,
          "height": 140,
          "fill": "#000000",
          "text": "Contact us for more information about how we can support your business growth with our expert services.",
          "fontSize": 18,
          "fontWeight": "normal",
          "fontFamily": "Arial, sans-serif",
          "textAlign": "left",
          "visible": true,
          "styles": {}
          }
      ],
      "background": "#ffffff"
      }
  }
      

];

export const mockComponents = {
  1: {
    id: 1,
    name: 'Header',
    thumbnail: '/images/header-component-thumbnail.jpg',
    description: 'A stylish header with a title and subtitle',
    canvasJson: {
      "version": "5.2.1",
      "objects": [
        {
          "type": "rect",
          "version": "5.2.1",
          "originX": "left",
          "originY": "top",
          "left": 0,
          "top": 0,
          "width": 900,
          "height": 100,
          "fill": "#4a90e2",
          "visible": true,
          "styles": {}
        },
        {
          "type": "textbox",
          "version": "5.2.1",
          "originX": "left",
          "originY": "top",
          "left": 20,
          "top": 20,
          "width": 860,
          "height": 40,
          "fill": "#ffffff",
          "text": "Website Title",
          "fontSize": 32,
          "fontWeight": "bold",
          "fontFamily": "Arial, sans-serif",
          "textAlign": "left",
          "visible": true,
          "styles": {}
        },
        {
          "type": "textbox",
          "version": "5.2.1",
          "originX": "left",
          "originY": "top",
          "left": 20,
          "top": 70,
          "width": 860,
          "height": 20,
          "fill": "#ffffff",
          "text": "Subtitle or slogan goes here",
          "fontSize": 18,
          "fontFamily": "Arial, sans-serif",
          "textAlign": "left",
          "visible": true,
          "styles": {}
        }
      ],
      "background": "transparent"
    }
  },
  2: {
    id: 2,
    name: 'Call to Action Button',
    thumbnail: '/images/cta-button-component-thumbnail.jpg',
    description: 'An eye-catching call to action button',
    canvasJson: {
      "version": "5.2.1",
      "objects": [
        {
          "type": "rect",
          "version": "5.2.1",
          "originX": "left",
          "originY": "top",
          "left": 0,
          "top": 0,
          "width": 200,
          "height": 50,
          "rx": 25,
          "ry": 25,
          "fill": "#ff6b6b",
          "visible": true,
          "styles": {}
        },
        {
          "type": "textbox",
          "version": "5.2.1",
          "originX": "left",
          "originY": "top",
          "left": 0,
          "top": 15,
          "width": 200,
          "height": 20,
          "fill": "#ffffff",
          "text": "Click Here!",
          "fontSize": 20,
          "fontWeight": "bold",
          "fontFamily": "Arial, sans-serif",
          "textAlign": "center",
          "visible": true,
          "styles": {}
        }
      ],
      "background": "transparent"
    }
  },
  3: {
    id: 3,
    name: 'Image Gallery',
    thumbnail: '/images/image-gallery-component-thumbnail.jpg',
    description: 'A responsive image gallery layout',
    canvasJson: {
      "version": "5.2.1",
      "objects": [
        {
          "type": "rect",
          "version": "5.2.1",
          "originX": "left",
          "originY": "top",
          "left": 0,
          "top": 0,
          "width": 900,
          "height": 300,
          "fill": "#f0f0f0",
          "visible": true,
          "styles": {}
        },
        {
          "type": "image",
          "version": "5.2.1",
          "left": 10,
          "top": 10,
          "width": 280,
          "height": 280,
          "src": "/images/gallery-placeholder-1.jpg",
          "visible": true,
          "styles": {}
        },
        {
          "type": "image",
          "version": "5.2.1",
          "left": 310,
          "top": 10,
          "width": 280,
          "height": 280,
          "src": "/images/gallery-placeholder-2.jpg",
          "visible": true,
          "styles": {}
        },
        {
          "type": "image",
          "version": "5.2.1",
          "left": 610,
          "top": 10,
          "width": 280,
          "height": 280,
          "src": "/images/gallery-placeholder-3.jpg",
          "visible": true,
          "styles": {}
        }
      ],
      "background": "transparent"
    }
  }
};


let scrollLeft = 0;
let scrollTop = 0;

export const setScrollPositionForComponents = (left, top) => {
  scrollLeft = left;
  scrollTop = top;

};

export const getScrollPosition = () => ({
  left: scrollLeft,
  top: scrollTop,
});
// Mockup templates for testing

export const canvasJsonToImage =  (canvasJson) => {
    const canvas = new fabric.Canvas(null, { width: 200, height: 150 }); // Adjust size as needed
    fabric.util.enlivenObjects(canvasJson.objects, (objects) => {
      objects.forEach(obj => canvas.add(obj));
      canvas.renderAll();

      const dataUrl = canvas.toDataURL({ format: 'png' });
      return dataUrl
  });
};
  // Function to add a template to the canvas
  export const addTemplateToCanvas = async (canvas, templateId) => {
    const template = await getTemplateById(templateId);
    console.log(template)
    if (!template) {
      console.error(`Template with id ${templateId} not found`);
      return;
    }
    
    enlivenAndAddObjects(canvas, template);
  };
  
  const enlivenAndAddObjects = (canvas, objects) => {
    console.log("objects", objects.name);
  
    // Enliven and add objects to the canvas
    fabric.util.enlivenObjects(objects.canvasJson.objects, (enlivenedObjects) => {
      // Add objects to the canvas
      enlivenedObjects.forEach(object => {
        canvas.add(object);
      });
  
      // Find the object with the largest top value
      const maxTop = enlivenedObjects.reduce((max, obj) => {
        return obj.top > max ? obj.top : max;
      }, 0);
  
      // Calculate the new canvas height
      const newHeight = Math.ceil(maxTop / 450) * 450;
  
      // Set the new canvas height
      canvas.setHeight(newHeight);
  
      // Render the canvas
      canvas.renderAll();
    });
  };
  
  // Function to get all templates (simulating an API call)
  export const getAllTemplates = () => {
    return new Promise((resolve) => {
      // Simulate API delay
      setTimeout(() => {
        const templates = Object.values(mockTemplates).map(({ id,canvasJson, name, thumbnail, description }) => ({ id, canvasJson, name, thumbnail, description }));
        resolve(templates);
      }, 300);
    });
  };
  
  // Function to get a specific template by ID (simulating an API call)
  export const getTemplateById = (id) => {
    return new Promise((resolve, reject) => {
      // Simulate API delay
      setTimeout(() => {
        const template = mockTemplates[id];
        if (template) {
          resolve(template);
        } else {
          reject(new Error(`Template with id ${id} not found`));
        }
      }, 300);
    });
  };

  
export const addComponentToCanvas = (canvas, componentId) => {
  const component = mockComponents[componentId];
  if (!component) {
    console.error(`Component with id ${componentId} not found`);
    return;
  }

  // Load the component's objects and group them
  fabric.util.enlivenObjects(component.canvasJson.objects, (objects) => {
    const group = createGroup(objects, canvas);
    canvas.add(group);
    canvas.renderAll();
  });
};

// Create a group from objects and add a double-click event listener
const createGroup = (objects, canvas) => {
  const group = new fabric.Group(objects, {
    left: 30 + scrollLeft,
    top: 100 + scrollTop,
    selectable: true,
    hasControls: true,
    evented: true,
  });

  // Add double-click event listener to ungroup
  group.on('mousedown', (e) => {
    if (e.e.detail === 2) { // Double-click detected
      ungroupObjects(group, canvas);
    }
  });

  return group;
};

const reCreateGroup = (objects, canvas, left , top) => {
  console.log("ran recreateGroup")
  const group = new fabric.Group(objects, {
    left: left,
    top: top,
    selectable: true,
    hasControls: true,
    evented: true,
  });

  group.on('mousedown', (e) => {
    if (e.e.detail === 2) { // Double-click detected
      ungroupObjects(group, canvas);
    }
  });
  
  const canvasJSON = canvas.toJSON();
  console.log(canvasJSON);
  return group;
}
// Ungroup the objects when double-clicked
const ungroupObjects = (group, canvas) => {
  const objects = group.getObjects();

  // Adjust each object's position relative to the canvas (keep their current positions)
  group.getObjects().forEach((object) => {
    const objectLeft = object.left ;
    const objectTop = object.top;

    object.set({
      left: objectLeft,
      top: objectTop,
      scaleX: object.scaleX * group.scaleX,
      scaleY: object.scaleY * group.scaleY,
      angle: object.angle + group.angle,
      selectable: true, // Make individual objects selectable
      evented: true,    // Allow individual object events
    });
  });

  group.destroy(); // Destroy the group

  // Add the individual objects back to the canvas
  objects.forEach((object) => {
    canvas.add(object);
  });

  canvas.remove(group);
  canvas.renderAll();

  // Enable regroup when clicking outside
  enableRegroupOnDeselect(objects, canvas);
};

// Function to regroup objects when clicked outside
const enableRegroupOnDeselect = (objects, canvas) => {
  const deselectHandler = (e) => {
    if (!e.target) {
      // Regroup the objects
      regroupObjects(objects, canvas);

      // Remove the deselect listener after regrouping
      canvas.off('mouse:down', deselectHandler);
    }
  };

  // Listen for object deselection (clicking outside)
  canvas.on('mouse:down', deselectHandler);
  
};

// Regroup objects back into a fabric.Group
const regroupObjects = (objects, canvas) => {
  // Calculate the new group's position based on the individual object positions
  const minLeft = Math.min(...objects.map(obj => obj.left));
  const minTop = Math.min(...objects.map(obj => obj.top));

  // Adjust each object's position relative to the new group
  objects.forEach((object) => {
    object.set({
      left: object.left - minLeft,
      top: object.top - minTop,
    });
  });

  const group = reCreateGroup(objects, canvas,minLeft,minTop); // Create a new group with the adjusted objects

  // Add the group back to the canvas
  canvas.add(group);
  objects.forEach((object) => canvas.remove(object));  // Remove individual objects

  canvas.renderAll();
};

// Function to get all components (simulating an API call)
export const getAllComponents = () => {
  return new Promise((resolve) => {
    // Simulate API delay
    setTimeout(() => {
      const components = Object.values(mockComponents).map(({ id,canvasJson, name, thumbnail, description }) => ({ id,canvasJson, name, thumbnail, description }));
      resolve(components);
    }, 300);
  });
};

// Function to get a specific component by ID (simulating an API call)
export const getComponentById = (id) => {
  return new Promise((resolve, reject) => {
    // Simulate API delay
    setTimeout(() => {
      const component = mockComponents[id];
      if (component) {
        resolve(component);
      } else {
        reject(new Error(`Component with id ${id} not found`));
      }
    }, 300);
  });
};
