

const Content = ()=>{

    return(
        <div className="bg-white w-[93%] mt-5 h-1/2">
            <div className="mt-4 ml-4  h-7">
                <div className="flex ">
                    <p className="font-bold uppercase text-sm">Active campaign</p>
                    <select className="ml-[75%] border-[2px] border-gray-200 rounded-lg bg-gray-100 text-gray-500">
                        <option>Today</option>
                        <option>Last 7 day</option>
                        <option>Last Months</option>
                        <option>All</option>
                    </select>
                </div>
                <div className="h-[1px] w-[98%] bg-gray-200 mt-1 "></div>
                <div className="flex w-[80%] mt-5 ">
                    <div className=" flex items-center justify-center w-[10%] h-16 mr-2 border-[1px] border-gray-200 rounded-lg">
                        Email
                    </div>
                    <div className=" flex items-center justify-center w-[10%] h-16 mr-2 border-[1px] border-gray-200 rounded-lg">
                        Twitter
                    </div>
                    <div className=" flex items-center justify-center w-[10%] h-16 mr-2 border-[1px] border-gray-200 rounded-lg">
                        Facebook
                    </div>
                    <div className=" flex items-center justify-center w-[10%] h-16 mr-2 border-[1px] border-gray-200 rounded-lg">
                        LinkedIn
                    </div>
                    <div className=" flex items-center justify-center w-[10%] h-16 mr-2 border-[1px] border-gray-200 rounded-lg">
                        Instagram
                    </div>
                </div>
                <div></div>
            </div>
        </div>
    )
}

export default Content;