
import React from 'react';
import { MdDeleteOutline } from "react-icons/md";

const DeleteElement = ({ canvas }) => {
  const deleteElement = () => {
    const activeObject = canvas?.getActiveObject();
    if (activeObject) {
      canvas.remove(activeObject);
      canvas.renderAll();
    }
  };

  return (
    <div className="mt-2">
      <MdDeleteOutline onClick={deleteElement} className="text-black shadow-sm  w-10 h-6" />
    </div>
  );
};

export default DeleteElement;
