import React from 'react';
import { CiLock } from "react-icons/ci";

const LockElement = ({ canvas }) => {
  const toggleLockElement = () => {
    const activeObject = canvas?.getActiveObject();
    if (activeObject) {
      const isLocked = activeObject.lockMovementX && activeObject.lockMovementY;
      activeObject.set({
        lockMovementX: !isLocked,
        lockMovementY: !isLocked,
        lockScalingX: !isLocked,
        lockScalingY: !isLocked,
        lockRotation: !isLocked,
        hasControls: true,
        hasBorders: true,
      });
      canvas.renderAll();
    }
  };

  return (
    <div className="mt-2">
      <CiLock onClick={toggleLockElement} className="text-black shadow-sm  w-10 h-6" />
    </div>
  );
};

export default LockElement;

