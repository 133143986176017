import {ListBulletIcon , Bars3Icon} from "@heroicons/react/24/solid";
import { GrOrderedList } from "react-icons/gr";
import { TfiUnderline } from "react-icons/tfi";
import { AiOutlineItalic } from "react-icons/ai";
import { FaBold } from "react-icons/fa";
import { toggleBold, toggleItalic, toggleUnderline } from "./TextUtils";

const TextStylesUtils = ({canvas})=>{

    return(
        <div className=" flex  w-60 text-slate-500 ">
            <div className="flex ml-2 mt-1">
                <div  className="mr-2 ">
                    <p className="text-black text-xl font-bold cursor-pointer" onClick={() => toggleBold(canvas)} ><FaBold size={18}/></p>
                </div>

                <div  className="mr-2 ml-3">
                    <p className="text-black text-xl  italic cursor-pointer" onClick={() => toggleItalic(canvas)} ><AiOutlineItalic size={18}/></p>
                </div>

                <div  className="mr-7 ml-3">
                    <p className="text-black text-xl font-bold underline decoration-solid cursor-pointer" onClick={() => toggleUnderline(canvas)}><TfiUnderline size={18}/></p>
                </div>
            </div>

            <div className="flex ml-9">
                <div  className="mr-2 ">
                    <ListBulletIcon className="h-6 text-slate-500 cursor-pointer" />
                </div>
                <div  className="mr-2 ml-2">
                    <GrOrderedList size={21} className="h-6 text-slate-500 cursor-pointer" />
                </div>
                <div  className="mr-2 ml-2">
                    <Bars3Icon className="h-6 text-slate-500 cursor-pointer" />
                </div>
            </div>
            
        </div>
        )
}

export default TextStylesUtils;